import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import LandingPage from './pages/LandingPage.js';
import Broadcaster from './pages/Broadcaster.js';
import LoginAdvertiser from "./pages/login-advertiser.js";
import LoginVendor from "./pages/login-vendor.js";
import SignupVendor from "./pages/signup-vendor.js";
import TalkWithAnExpert from "./pages/TalkWithExpert.js";
import BookCampaign from "./pages/BookCampaign.js";
import PageOne from "./bookCampaign/pages/PageOne.js";
import PageTwo from "./bookCampaign/pages/PageTwo.js";
import PageThree from "./bookCampaign/pages/PageThree.js";
import DashboardVendor from './pages/dashboard-vendor';
import DashboardStation from './pages/dashboard-station';
import DashboardReport from './pages/dashboardReport';
import PaymentPage from './pages/paymentPage';
import ProgramSchedulePage from './pages/programSchedulePage';
import CampaignPage from './pages/campaignPage';

import Dashboard from './pages/ResponsiveAdvertiserDashboard/AdvertiserPages/Pages/dashboard';

import StationDashboard from './pages/ResponsiveAdvertiserDashboard/AdvertiserPages/Pages/station-dashboard';
import StationBooking from './pages/ResponsiveAdvertiserDashboard/AdvertiserPages/Pages/station-booking';
import Draft from './pages/ResponsiveAdvertiserDashboard/AdvertiserPages/Pages/draft';
import Checkout from './pages/ResponsiveAdvertiserDashboard/AdvertiserPages/Pages/checkout';
import Wallet from './pages/ResponsiveAdvertiserDashboard/AdvertiserPages/Pages/wallet';
import Profile from './pages/ResponsiveAdvertiserDashboard/AdvertiserPages/Pages/profile';
import ResetPassword from './pages/ResponsiveAdvertiserDashboard/AdvertiserPages/Pages/reset-password';
import VendorDashbaordLayout from './pages/ResponsiveAdvertiserDashboard/ResponsiveAdvertiserDashboardLayout/vendorDashboard/VendorDashbaordLayout';

import Slot from './components/Modal/Slot';
import ProtectedRoute from './protectedRoute';
import AdvertiserSignup from './pages/signup/advertiserSignup';
import TimeSchedule from './bookCampaign/components/TimeSchedule';
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<LandingPage />} />
          <Route path="broadcaster" element={<Broadcaster />} />
          <Route path="talk-with-an-expert" element={<TalkWithAnExpert />} />
          <Route path="login" element={<LoginAdvertiser />} />
          <Route path="login/vendor" element={<LoginVendor />} />
          <Route path="signup" element={<AdvertiserSignup />} />
          <Route path="signup/vendor" element={<SignupVendor />} />
          <Route path="/book-campaign/" element={<BookCampaign />}>
            <Route path="" element={<PageOne />} />
            <Route path="/book-campaign/campaign-details" element={<PageTwo />} />
            <Route path="campaign-checkout" element={<PageThree />} />
          </Route>
          <Route path='/dashboard/vendor' element={
          <ProtectedRoute>
          <DashboardVendor />
          </ProtectedRoute>
          } />
          <Route path='/dashboard/vendor/station' element={
            <ProtectedRoute>
          <DashboardStation />
            </ProtectedRoute>
          } />
          <Route path='/dashboard/vendor/campaign-page' element={
            <ProtectedRoute>
          <CampaignPage />
            </ProtectedRoute>
          } />
          <Route path='/dashboard/vendor/reports' element={
            <ProtectedRoute>
          <DashboardReport />
            </ProtectedRoute>
          } />
          <Route path='/dashboard/vendor/payment' element={
            <ProtectedRoute>
          <PaymentPage />
            </ProtectedRoute>
          } />
          <Route path='/dashboard/vendor/program-schedule' element={
            <ProtectedRoute>
          <ProgramSchedulePage />
            </ProtectedRoute>
          } />
          {/* <Route path='/advertiser/dashboard' element={<AdvertiserDashvendor />} />
          <Route path='/advertiser/station' element={<ProgramSchedulePage />} />
          <Route path='/advertiser/campaign' element={<AdvertiserDashCampaign />} />
          <Route path='/advertiser/draft' element={<Advertiserdraft />} />
          <Route path='/advertiser/wallet' element={<AdvertiserWallet />} />
          <Route path="/advertiser/profile" element={<AdvertiserProfilePage />} />
          <Route path='page' element={<CampaignDetails />} /> */}

      {/* <ProtectedRoute path='/dashboard' element={Dashboard} /> */}
        
          <Route path='/dashboard' element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />
          {/* </ProtectedRoute> */}

          <Route path="/dashboard/station" element={
            <ProtectedRoute>
              <StationDashboard />
            </ProtectedRoute>
          } />
          <Route path='/dashboard/booking' element={
          <ProtectedRoute>
          <StationBooking />
          </ProtectedRoute>
          } />
          <Route path='/dashboard/draft' element={
          <ProtectedRoute>
          <Draft />
          </ProtectedRoute>
          } />
          <Route path='/dashboard/checkout' element={
          <ProtectedRoute>
          <Checkout />
          </ProtectedRoute>
          } />
          <Route path='/dashboard/wallet' element={
          <ProtectedRoute>
          <Wallet />
          </ProtectedRoute>
          } />
          <Route path='/dashboard/profile' element={
          <ProtectedRoute>
          <Profile />
          </ProtectedRoute>
          } />
          <Route path='/profile/reset-password' element={
            <ProtectedRoute>
          <ResetPassword />
            </ProtectedRoute>
          } />
          <Route path='/vendor/dashboard' element={<VendorDashbaordLayout />} />
          <Route path='/station' element={<Slot />} />
        </Route>
       {/* <Route path='/time' element={<TimeSchedule />}/> */}

        {/* responsive design */}

      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
