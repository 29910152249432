import React, { useState, useEffect } from 'react'
import EditIcon from "../assets/dashboardIcons/editIcon.svg"
import DeleteIcon from "../assets/dashboardIcons/deleteIcon.svg"
import ScheduleModal from "../components/Modal/ScheduleModal/ScheduleModal.js"
import "./css/program-schedule.css"
import VendorDashbaordLayout from './ResponsiveAdvertiserDashboard/ResponsiveAdvertiserDashboardLayout/vendorDashboard/VendorDashbaordLayout'

const ProgramSchedulePage = () => {
  const data = [
    {
      Monday: [
        { show: "Drovetime Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Morning Rush", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Midday Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Happy MonDay", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Fun Wednesday", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
      ],
      Tuesday: [
        { show: "Drivetime Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Morning Rush", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Midday Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Happy TuesDay", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Fun Wednesday", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
      ],
      Wednesday: [
        { show: "Drivetime Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Morning Rush", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Midday Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Happy WednesDay", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Fun Wednesday", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
      ],
      Thursday: [
        { show: "Drivetime Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Morning Rush", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Midday Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Happy ThursDay", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Fun Wednesday", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
      ],
      Friday: [
        { show: "Drivetime Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Morning Rush", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Midday Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Happy FriDay", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Fun Wednesday", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
      ],
      Saturday: [
        { show: "Drivetime Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Morning Rush", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Midday Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Happy SaturDay", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Fun Wednesday", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
      ],
      Sunday: [
        { show: "Drivetime Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Morning Rush", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Midday Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Happy Sun Day", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Fun Wednesday", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
      ],
    },
  ]
let daySchedule;
  let changeSchedule = (dayArray) => {
    switch (dayArray) {
      case "Monday":
        console.log(data[0].Monday)
          daySchedule = data[0].Monday
        break;
      case "Tuesday":
        daySchedule = data[0].Tuesday
        break;
      case "Wednesday":
        daySchedule = data[0].Wednesday
        break;
      case "Thursday":
        daySchedule = data[0].Thursday
        break;
      case "Friday":
        daySchedule = data[0].Friday
        break;
      case "Saturday":
        daySchedule = data[0].Saturday
        break;
      case "Sunday":
        daySchedule = data[0].Sunday
        break;
      default:
        break;
    }

  };

  const [getDay, setGetDay] = useState(0)
  const [dayData, setDayData] = useState([])
  const [isModal, setIsModal] = useState(false)
  const dayArray = Object.keys(data[0])
  useEffect(() => {
    const getData = changeSchedule(dayArray[getDay])
    console.log(getData, 'getData')
    setDayData(daySchedule)
  }, [getDay])
  return (
    <>
      {isModal && <ScheduleModal />}
      <VendorDashbaordLayout>
        <section className='program-schedule-container'>
          <h4>program schedule</h4>
          <div id="day-btn">
            {dayArray.map((day, index) => {
              return <button
                onClick={() => setGetDay(index)}
                className = {index === getDay ? "bg-color" : ""}
              >{day}</button>
            })}
          </div>
          <section className='program-schedule-table-container'>
            <div className='schedule-button-wrapper'>
              <button onClick={() => setIsModal(true)}>Add Schedule</button>

            </div>
            <div className='table-icons-container'>
              <div className='icon-text-wrapper'>
                <img src={EditIcon} alt="edit-icon" />
                <span>Edit</span>
              </div>
              <div className='icon-text-wrapper'>
                <img src={DeleteIcon} alt="delete-icon" />
                <span>Delete</span>

              </div>
            </div>
            <div className="panel-body table-body-container">
              <table>
                <thead>
                  <tr>
                    <th><input name="select_all" value="1" type="checkbox" /></th>
                    <th>show</th>
                    <th>time</th>
                    <th>sponsor</th>
                    <th>status</th>
                  </tr>
                </thead>
                <tbody>

                  {dayData.map((data) => {
                    return (
                      <tr>
                        <td><input type="checkbox" name="name1" /></td>
                        <td>{data.show}</td>
                        <td>{data.time}</td>
                        <td>{data.sponsor}</td>
                        <td>
                          <select className='schedule-option'>
                            <option>Available</option>
                            <option>Not Available</option>
                          </select>
                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </table>
            </div>
          </section>
        
        </section>


        </VendorDashbaordLayout>

    </>

  )
}

export default ProgramSchedulePage