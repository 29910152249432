import React from 'react'
import DashboardTable from '../components/DashboardLayout/DashboardTable'
import "./css/payment.css"
import VendorDashbaordLayout from './ResponsiveAdvertiserDashboard/ResponsiveAdvertiserDashboardLayout/vendorDashboard/VendorDashbaordLayout'

const PaymentPage = () => {
    const data = [
        {

            amount: "₦645.00",
            date: "Mar 28th 2022",
            amountSpent: "₦2,741.25",
            status: "pending",
            paymentMethod: "flutterwave"
        },
        {

            amount: "₦645.00",
            date: "Mar 28th 2022",
            amountSpent: "₦2,741.25",
            status: "pending",
            paymentMethod: "flutterwave"
        },
        {

            amount: "₦645.00",
            date: "Mar 28th 2022",
            amountSpent: "₦2,741.25",
            status: "pending",
            paymentMethod: "flutterwave"
        },
        {

            amount: "₦645.00",
            date: "Mar 28th 2022",
            amountSpent: "₦2,741.25",
            status: "pending",
            paymentMethod: "flutterwave"
        },
    ]
    return (
        <VendorDashbaordLayout>
            <div className='payment-conatiner'>
                <section className='input-payment-section'>
                    <div className='payment-heading'>
                        <h2>Payments</h2>
                        <div className='amount-wrapper'>
                            <div className='current-balance'>current balance <span>₦44,902.00 </span></div>
                            <div className='pending-balance'>pending amount<span>₦4,000.00</span></div>
                        </div>
                    </div>
                    <div className='payment-input'>
                        <div className='amount-input'>
                            <label>Withdrawal Amount: </label>
                            <input type='text' placeholder='Enter Amount' />
                            <span>Withdrawal Request (Within 24hrs)</span>
                        </div>
                        <div className='amount-input'>
                            <label>Payment Method:  </label>
                            <select>
                                <option>Bank Transfer</option>
                                <option>Paystack</option>
                                <option>Flutterwave</option>
                            </select>
                        </div>
                    </div>
                    <button>Submit Request</button>
                </section>
                <section className='payment-table'>
                    <h2>payment history</h2>
                    <DashboardTable />
                    <div class="panel-body table-body-container">
                        <table>
                            <thead>
                                <tr>
                                    <th><input name="select_all" value="1" type="checkbox" /></th>
                                    <th>transaction date</th>
                                    <th>amount</th>
                                    <th>status</th>
                                    <th>payment method</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((info) => {
                                    return (
                                        <tr>
                                            <td><input type="checkbox" name="name1" /></td>
                                            <td>{info.date}</td>
                                            <td>{info.amount}</td>
                                            <td>{info.status}</td>
                                            <td>{info.paymentMethod}</td>
                                        </tr>


                                    )
                                })}
                            </tbody>
                        </table>

                    </div>
                                  
                <section className='pagination-section'>
                    <div>
                        Showing 1 to 3 of 3 entries
                    </div>
                </section>

                </section>
            </div>
            </VendorDashbaordLayout>

    )
}

export default PaymentPage