import React from 'react'
import "./css/campaign.css"
import DashboardTable from '../components/DashboardLayout/DashboardTable';
import VendorDashbaordLayout from './ResponsiveAdvertiserDashboard/ResponsiveAdvertiserDashboardLayout/vendorDashboard/VendorDashbaordLayout';


const CampaignPage = () => {
    const data = [
        {
            campaignId: "#467666",
            amountSpent: "₦2,741.25",
            amountEarned: "₦645.00",
            date: "Mar 28th 2022",
            status: "pending"
        },
        {
            campaignId: "#467666",
            amountSpent: "₦2,741.25",
            amountEarned: "₦645.00",
            date: "Mar 28th 2022",
            status: "pending"
        },
        {
            campaignId: "#467666",
            amountSpent: "₦2,741.25",
            amountEarned: "₦645.00",
            date: "Mar 28th 2022",
            status: "pending"
        },
        {
            campaignId: "#467666",
            amountSpent: "₦2,741.25",
            amountEarned: "₦645.00",
            date: "Mar 28th 2022",
            status: "pending"
        },
        {
            campaignId: "#467666",
            amountSpent: "₦2,741.25",
            amountEarned: "₦645.00",
            date: "Mar 28th 2022",
            status: "pending"
        },
        {
            campaignId: "#467666",
            amountSpent: "₦2,741.25",
            amountEarned: "₦645.00",
            date: "Mar 28th 2022",
            status: "pending"
        },
        {
            campaignId: "#467666",
            amountSpent: "₦2,741.25",
            amountEarned: "₦645.00",
            date: "Mar 28th 2022",
            status: "pending"
        },
    ]
    return (
    <VendorDashbaordLayout>
            <div className='campaign-dashboard-container'>
                <h4>campaigns</h4>
                <>
                <DashboardTable />
                <div class="panel-body table-body-container">
                    <table>
                        <thead>
                            <tr>
                                <th><input name="select_all" value="1" type="checkbox" /></th>
                                <th>campaign id</th>
                                <th>total amount spent</th>
                                <th>total earnings</th>
                                <th>date</th>
                                <th>status</th>
             
                            </tr>
                        </thead>
                        <tbody>

                            {data.map((info) => {
                                console.log(data, 'data')
                                return (
                                    <tr>
                                        <td><input type="checkbox" name="name1" /></td>
                                        <td className='campaign-id'>{info.campaignId}</td>
                                        <td>{info.amountSpent}</td>
                                        <td>{info.amountEarned}</td>
                                        <td>{info.date}</td>
                                        <td>{info.status}</td>
                                    </tr>


                                )
                            })}
                           
                        </tbody>
                    </table>
                </div>
                <section  className='pagination-section'>
                    <div>
                        Showing 1 to 3 of 3 entries
                    </div>
                </section>
                </>
            </div>
            
            </VendorDashbaordLayout>
    )
}

export default CampaignPage