import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./css/login.css";
import Logo from "../components/Logo";
import { ReactComponent as See } from "../assets/icons/eye.svg";
import ResetPassword from "../components/resetPassword.js";
import { AUTH_TOKEN } from "../constant";
import { useMutation } from "@apollo/client";
import { ADVERTISER_LOGIN } from "../components/GraphQL/Mutation";
import { Formik } from "formik";

const LoginVendor = ({
  navTextColor = "#004643",
  navFavColor = "#FC9732",
}) => {
  const navigate = useNavigate()
  const [reset, setReset] = useState(false);

  // const [userInfo, setUserInfo] = useState({
  //   email: "",
  //   password: ""
  // })
  // const handleChange = (e) => {
  //   setUserInfo({ ...userInfo, [e.target.name]: e.target.value })
  // }

  const [login] = useMutation(ADVERTISER_LOGIN)

  // const handleSubmit = () => {
  //   login({
  //     variables: {
  //       email: userInfo.email,
  //       password: userInfo.password
  //     },
  //     onCompleted: (data) => {
  //       console.log(data)
  //       localStorage.setItem(AUTH_TOKEN, data.auth.login.token);
  //       setTimeout(navigate("/dashboard"), 10000)
  //     }
  //   })
  // }

  const loginPassword = useRef();
  const checkMark = useRef();
  const Modal = useRef();

  useEffect(() => {
    checkMark.current.style.display = "none";
  }, [checkMark]);

  useEffect(() => {
    if (reset) {
      document.body.style.height = "100vh";
      document.body.style.overflow = "hidden";
      Modal.current.style.display = "flex";
    } else {
      document.body.style.height = "100%";
      document.body.style.overflow = "auto";
      Modal.current.style.display = "none";
    }
  }, [reset]);

  return (
    <section id="login">
      <header>
        <nav>
          <Link to="/">
            <Logo
              textColor={navTextColor}
              favColor={navFavColor} /*  , hr - background: #ABD1C61A; */
            />
          </Link>
        </nav>
      </header>
      <main>
        <div>
          <div>
            <h2>Welcome Back!</h2>
            <h1>Log in to your Dashboard</h1>
          </div>
          <Formik
            initialValues={{ email: '', password: '' }}
            validate={values => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              if (!values.password) {
                errors.password = 'password is required'
              } else if (values.password.length < 8) {
                errors.password = 'password must be greater than 8 character'
              }
              return errors;
            }}
            onSubmit={(values) => {
              login({
                variables: {
                  email: values.email,
                  password: values.password
                },
                onCompleted: (data) => {
                  console.log(data)
                  localStorage.setItem(AUTH_TOKEN, data.auth.login.token);
                  setTimeout(navigate("/dashboard/vendor"), 10000)
                }
              })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
          <div className="form-container">
          <div>
            <label className="login-label" htmlFor="login-username">
              Email
            </label>
            <input
              id="login-username"
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="John Doe"
              required
            />
                        <span>
                    {errors.email && touched.email && errors.email}
                  </span>
          </div>
          <div>
            <label className="login-label" htmlFor="login-password">
              Password
            </label>
            <input
              id="login-password"
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter your Password"
              required
              ref={loginPassword}
            />
            <button
              type="button"
              id="show-password"
              onClick={() => {
                if (loginPassword.current.type === "text") {
                  loginPassword.current.type = "password";
                } else {
                  loginPassword.current.type = "text";
                }
              }}
            >
              <See />
            </button>
            <span>
                    {errors.password && touched.password && errors.password}
                  </span>
          </div>
          <div id="login-option">
            <div>
              <button
                type="button"
                onClick={() => {
                  if (checkMark.current.style.display === "none") {
                    checkMark.current.style.display = "block";
                  } else {
                    checkMark.current.style.display = "none";
                  }
                }}
              >
                <svg
                  ref={checkMark}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg>
              </button>
              <span>Remember Me</span>
            </div>
            <button
              type="button"
              id="forgot-password"
              onClick={() => {
                setReset(true);
              }}
            >
              Forgot Password?
            </button>
          </div>
          <button
            id="login-button"
            type="submit"
            onClick={handleSubmit}
          >Log in</button>
          </div>
                    )}
                    </Formik>
          <p>
            Don't have an account?{" "}
            <Link to="/signup" id="go-to-signup">
              Click here
            </Link>{" "}
            to Sign up
          </p>
        </div>
      </main>
      <aside
        ref={Modal}
        onClick={(e) => {
          const child = Modal.current.querySelector("#reset-password");
          if (!child.contains(e.target)) {
            setReset(false);
          }
        }}
      >
        <ResetPassword visibility={reset} />
      </aside>
    </section>
  );
};

export default LoginVendor;
