import React from "react";
import "./css/search-radio-station.css";
import RecentSearch from "./RecentSearch.js";
const SearchRadioStation = () => {
  return (
    <section id="search-radio-station">
      <div>
        <h1>Search for and discover your preferred radio station</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div>
            <div>
              <input type="text" placeholder="Select Country" readOnly={true} />
              <svg
                width="13"
                height="8"
                viewBox="0 0 13 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.6665 1.5L6.6665 6.5L11.6665 1.5"
                  stroke="#0F3433"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div>
              <input type="text" placeholder="Select State" readOnly={true} />
              <svg
                width="13"
                height="8"
                viewBox="0 0 13 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.6665 1.5L6.6665 6.5L11.6665 1.5"
                  stroke="#0F3433"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <button type="submit">Find a Radio Station</button>
        </form>
        <div>
          <h2>Popular Searches</h2>
          <div
            onBlur={(e) => {
              e.target.style.scroll = "hidden";
            }}
          >
            <div>
              <RecentSearch searchName="Lagos State" />
              <RecentSearch searchName="Oyo State" />
              <RecentSearch searchName="Federal Capital Territory" />
              <RecentSearch searchName="Ogun State" />
              <RecentSearch searchName="Osun State" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchRadioStation;
