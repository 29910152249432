import React from "react";
import "./css/page-one.css";
import SearchRadioStation from "../components/SearchRadioStation.js";
import BudgetFilter from "../components/BudgetFilter.js";
import RecommendedStation from "../components/RecommendedStation.js";
import Newsletter from "../../components/Newsletter.js";
import MainFooterSect from "../../components/MainFooterSect.js";

const PageOne = () => {
  return (
    <div id="book-campaign">
      <main>
        <SearchRadioStation />
        <section id="select-station-sect">
          <div>
            <BudgetFilter />
            <RecommendedStation />
          </div>
        </section>
      </main>
      <footer>
        <Newsletter />
        <MainFooterSect />
      </footer>
    </div>
  );
};

export default PageOne;
