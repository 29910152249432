import React, { useState } from 'react'

import EditIcon from "../assets/dashboardIcons/editIcon.svg"
import DeleteIcon from "../assets/dashboardIcons/deleteIcon.svg"
import "./css/dashboard-station.css"

import VendorDashbaordLayout from './ResponsiveAdvertiserDashboard/ResponsiveAdvertiserDashboardLayout/vendorDashboard/VendorDashbaordLayout'


const DashboardStation = () => {

  const data = ["Discounts Rates", "Jingles Rates", "Paid Announcement Rates"]
  const timeButton = [
    "12:00am - 12:59am",
    "01:00am - 01:59am",
    "02:00am - 02:59am",
    "03:00am - 03:59am",
    "04:00am - 04:59am",
    "05:00am - 05:59am",
    "06:00am - 06:59am",
    "07:00am - 07:59am",
    "08:00am - 08:59am",
    "09:00am - 09:59am",
    "10:00am - 10:59am",
    "11:00am - 11:59am",
    "12:00pm - 12:59pm",
    "01:00pm - 01:59am",
  ]
  const [pageIndex, setPageIndex] = useState(0)
  return (
<VendorDashbaordLayout>
      <h4 className='station-header'>edit advert prices</h4>
      <div className='station-container'>

        <div className='btn-option-wrapper'>
          {data.map((btn, index) => {
            return <button
              onClick={() => setPageIndex(index)}
              className = {index === pageIndex ? "bg-color" : ""}
            >{btn}</button>
          })}
        </div>
        {pageIndex === 0 &&
          <>
            <div className='station-input-wrapper'>
              <div className='station-input-first-wrapper'>
                <div>
                  <label>Starting Price</label>
                  <input
                    type='text'
                    className='input-price'
                  />
                </div>
                <div>
                  <label>Starting Discount in %</label>
                  <input
                    type='text'
                    className='input-price'
                  />
                </div>
              </div>
              <>
                <h4 className='discunt-subheader'>Volume Discount</h4>
                <div className='station-second-wrapper'>
                  <div className='station-select-wrapper'>
                    <div className='select-wrapper'>
                      <label htmlFor='starting-spot'>from spot</label>
                      <select id='starting-spot' name='starting-spot'>
                        <option>10</option>
                        <option>10</option>
                        <option>10</option>
                        <option>10</option>
                      </select>
                    </div>
                    <div className='select-wrapper'>
                      <label htmlFor='starting-spot'>to spot</label>
                      <select id='stopping-spot' name='stopping-spot'>
                        <option>10</option>
                        <option>10</option>
                        <option>10</option>
                        <option>10</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label>Discount in %</label>
                    <input
                      type='text'
                      className='input-price'
                    />
                  </div>
                </div>
              </>
              <div className='save-btn'>
                <button>save</button>
              </div>
            </div>
            <div className='table-container'>
              <h4>list of discounts</h4>
              <div className='table-icons-container'>
                <div className='icon-text-wrapper'>
                  <img src={EditIcon} alt="edit-icon" />
                  <span>Edit</span>
                </div>
                <div className='icon-text-wrapper'>
                  <img src={DeleteIcon} alt="delete-icon" />
                  <span>Delete</span>
                </div>
              </div>
              <div className='table-body-container'>
                <table>
                  <thead>
                    <tr>
                      <th><input name="select_all" value="1" type="checkbox" /></th>
                      <th>spot</th>
                      <th>discount</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><input type="checkbox" name="name1" /></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><input type="checkbox" name="name2" /></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><input type="checkbox" name="name3" /></td>
                      <td></td>
                      <td></td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        }
      </div>

      {pageIndex === 1 &&
        <div className='jingles-container'>
          <div className='jingles-wrapper'>
          <div className='jingles-wrapper-info'>
            <div className='duration-btn'>
              {timeButton.map((btn) => {
                return <button>{btn}</button>
              })}
            </div>

            <div className='price-input-container'>
              <div className='durartion-input'>
                <span>15 Seconds</span>
                <span>30 Seconds</span>
                <span>45 Seconds</span>
                <span>60 Seconds</span>
              </div>
              <div className='price-field-wrapper'>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
              </div>

            </div>
          </div>
          <div className='save-btn jingles-btn'>
            <button>save</button>
          </div>
          </div>
        </div>
      }
      {pageIndex === 2 &&
        <div className='paid-annoucement-container'>
          <div className='paid-annoucement-wrapper'>
          <div className='paid-annoucement-wrapper-info'>
            <div className='duration-btn'>
              {timeButton.map((btn) => {
                return <button>{btn}</button>
              })}
            </div>

            <div className='price-input-container'>
              <div className='amount-of-words-input'>
                <span>50 words</span>
                <span>75 words</span>
                <span>100 words</span>
              </div>
              <div className='paid-annoucement-field-wrapper'>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
                <div>
                  <input
                    type='text'
                    className='price-input-field'
                  />
                </div>
              </div>

            </div>
          </div>
          <div className='save-btn jingles-btn'>
            <button>save</button>
          </div>
          </div>
        </div>
      }

</VendorDashbaordLayout>
  )
}

export default DashboardStation;