import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../../../components/Logo'
import Hamburger from "./../../assets/hamburger.svg"
import Notification from "../../assets/notification.svg"
import UserIcon from "../../assets/user.svg"
import BalanceIcon from "../../assets/balance.svg"
import DashHomeIcon from "../../../../assets/dashboardIcons/dashHomeIcon.svg"
import StationIcon from "../../../../assets/dashboardIcons/stationIcon.svg"
import DashCampaignIcon from "../../../../assets/dashboardIcons/dashCampaignIcon.svg"
import ReportIcon from "../../../../assets/dashboardIcons/reportIcon.svg"
import PaymentIcon from "../../../../assets/dashboardIcons/paymentIcon.svg"
import AnalyticsIcon from "../../../../assets/dashboardIcons/analyticsIcon.svg"
import ScheduleIcon from "../../../../assets/dashboardIcons/scheduleIcon.svg"
import { useQuery } from '@apollo/client';
import "./style.css"
import { USER_INFO } from '../../../../components/GraphQL/Queries'

const VendorDashbaordLayout = ({ name, children }) => {
    const menuData = [
        {
            img: DashHomeIcon,
            name: "Vendor Dashboard",
            href: "/dashboard/vendor"
        },
        {
            img: StationIcon,
            name: "Radio Station",
            href: '/dashboard/vendor/station'
        },
        {
            img: DashCampaignIcon,
            name: "Campaigns",
            href: "/dashboard/vendor/campaign-page"
        },
        {
            img: ReportIcon,
            name: "Reports",
            href: "/dashboard/vendor/reports"
        },
        {
            img: PaymentIcon,
            name: "Payment",
            href: "/dashboard/vendor/payment"
        },
        {
            img: AnalyticsIcon,
            name: "Analytics",
            href: ""
        },
        {
            img: ScheduleIcon,
            name: "Program Schedule",
            href: "/dashboard/vendor/program-schedule"
        },
    ]
    const [isOpen, setIsOpen] = useState(false);
    // const navigate = useNavigate()
    const {data} = useQuery(USER_INFO)
    return (
        <>
            <div className="advert-dashboard-layout">
                <nav>
                    <div className='advert-dashboard-nav-container'>
                        <div className='nav-wrapper'>
                            <div>
                                <Logo
                                    textColor="#FFFFFE"
                                    favColor="#FC9732"
                                />

                            </div>
                            <div onClick={() => setIsOpen(!isOpen)}>
                                <img src={Hamburger} alt="hamburger_icon" />
                            </div>
                        </div>


                        <div className='advertiser-name'>{name}</div>
                    </div>
                    {isOpen && <div className='mobile-show-nav-menu-container'>
                        <div className='mobile-sidebar-wrapper'>
                            <div>
                                <Logo
                                    textColor="#FFFFFE"
                                    favColor="#FC9732"
                                />

                            </div>
                            <div className='mobile-icon-wrapper'>

                                <img src={Notification} alt="notification_icon" />
                                <img src={UserIcon} alt="user_icon" />
                            </div>
                        </div>
                        <div className='mobile-sidebar-menu'>
                            {menuData.map((data) => {
                                return (
                            
                                    <Link to={data.href}>

                                        <div className='mobile-sidebat-item'
                                        >
                                            <img src={data.img} alt={data.name} />
                                            <span>{data.name}</span>
                                        </div>
                                    </Link>
                            
                                )
                            })}
                        </div>
                    </div>}
                </nav>

                {children}
            </div>

            <div className='desktop-dashboard-layout'>
                <div className='dashboard-sidebar-container'>
                    <div>
                        <Logo
                            textColor="#FFFFFE" favColor="#FC9732"
                        />
                    </div>

                    <div className='dashboard-sidebar-menu-items'>
                        {menuData.map((item) => {
                            return <>
                                <Link to={item.href}>

                                    <div className="sidebar-menu-item"
                                    >
                                        <img src={item.img} alt={item.name} />
                                        <span>{item.name}</span>
                                    </div>
                                </Link>
                            </>

                        })}
                    </div>
                </div>
                <div className='dashboard-nav-body-container'>
                    <nav>
                        <div>{data?.auth.me.fullname}</div>
                        <div className='nav-icon-wrapper'>
                            <img src={BalanceIcon} alt='balance_icon' />

                            <img src={Notification} alt='notification' />
                            <img src={UserIcon} alt='user-icon' />
                        </div>
                    </nav>
                    {children}
                </div>
            </div>
        </>
    )
}

export default VendorDashbaordLayout