import { gql } from "@apollo/client";

export const USER_INFO = gql`
query Auth {
  auth {
    me {
      id
      fullname
    }
  }
}
`