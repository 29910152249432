import React from "react";
import "../css/newsletter.css";

const Newsletter = ()=> {
    return(
        <section id="newsletter">
            <div>
                <div>
                    <h2>New to Radioadspread?</h2>
                    <p>Subcribe to our Newsletter to get update on our latest offers!</p>
                </div>
                <div>
                    <input placeholder="Enter Your Email Address" />
                    <button>Subscribe</button>
                </div>
            </div>
        </section>
    )
}

export default Newsletter;