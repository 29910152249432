import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BROADCASTER_REGISTER } from "../components/GraphQL/Mutation";
import { useMutation } from "@apollo/client"
import "./css/signup-vendor.css";
import { ReactComponent as See } from "../assets/icons/eye.svg";
import { AUTH_TOKEN } from "../constant";
import { useFormik } from "formik";

const SignupVendor = () => {

  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmAgreement, setConfirmAgreement] = useState(true)

  const [broadcasterSignup] = useMutation(BROADCASTER_REGISTER)
  // validate={values => {
  //   const errors = {};
  //   if (!values.name || values.name.length < 3) {
  //     errors.email = 'name cannot be empty and less than three';
  //   }
  //   if (!values.fullname || values.fullname.length < 3) {
  //     errors.fullname = 'fullname cannot be empty and less than three';
  //   }
  //   if (!values.industry || values.industry.length < 3) {
  //     errors.industry = 'industry cannot be empty and less than three';
  //   }
  //   if (!values.address || values.address.length < 3) {
  //     errors.address = 'address cannot be empty and less than three';
  //   }
  //   if (!values.country || values.country.length < 3) {
  //     errors.country = 'country cannot be empty and less than three';
  //   }
  //   if (!values.state || values.state.length < 3) {
  //     errors.state = 'state cannot be empty and less than three';
  //   }
  //   if (!values.city || values.city.length < 3) {
  //     errors.city = 'city cannot be empty and less than three';
  //   }
  //   if (!values.zipcode || values.zipcode.length < 3) {
  //     errors.zipcode = 'zipcode cannot be empty and less than three';
  //   }
  //   if (!values.phoneNumber || values.phoneNumber.length < 3) {
  //     errors.phoneNumber = 'phoneNumber cannot be empty and less than three';
  //   }
  //   if (!values.email || values.email.length < 3) {
  //     errors.email = 'email cannot be empty and less than three';
  //   }
  //   if (!values.username || values.username.length < 3) {
  //     errors.username = 'username cannot be empty and less than three';
  //   }
  //   if (!values.password || values.password.length < 8) {
  //     errors.password = 'password cannot be empty and less than eight';
  //   }


  //   if (!values.password) {
  //     errors.password = 'password is required'
  //   } else if (values.password.length < 8) {
  //     errors.password = 'password must be greater than 8 character'
  //   }
  //   if (values.confirmPassword !== values.password) {
  //     errors.email = 'confirmPassword cannot be empty and less than three';
  //   }
  //   return errors;
  // }}

  const formik = useFormik({
    initialValues: {
      name: "",
      fullname: "",
      address: "",
      country: "",
      state: "",
      city: "",
      email: "",
      phoneNumber: "",
      radioStationWebsite: "",
      radioStationDigitalStreaUrl: "",
      radioStationCategory: "",
      positionHeld: "",
      zipcode: "",
      password: "",
      confirmPassword: "",

    },

    onSubmit: (values) => {
      broadcasterSignup({
        variables: {
          name: values.name,
          fullname: values.fullname,
          address: values.address,
          country: values.country,
          state: values.state,
          city: values.city,
          email: values.email,
          phoneNumber: values.phoneNumber,
          username: values.username,
          zipcode: values.zipcode,
          password: values.password,
          confirmPassword: values.confirmPassword,
          radioStationWebsite: values.radioStationWebsite,
          radioStationDigitalStreaUrl: values.radioStationDigitalStreaUrl,
          radioStationCategory: values.radioStationCategory,
          positionHeld: values.positionHeld,

        },
        onCompleted: (data) => {
          localStorage.setItem(AUTH_TOKEN, data.auth.advertiserRegister.token);
          setTimeout(navigate("/dashboard/vendor"), 10000);
        }
      })
    }
  })

  // const [userInfo, setUserInfo] = useState({
  //   zipcode: "",
  //   state: "",
  //   radioStationWebsite: "",
  //   radioStationDigitalStreaUrl: "",
  //   radioStationCategory: "",
  //   positionHeld: "",
  //   phoneNumber: "",
  //   password: "",
  //   name: "",
  //   fullname: "",
  //   email: "",
  //   country: "",
  //   confirmPassword: "",
  //   city: "",
  //   address: ""
  // })
  // const handleChange = (e) => {
  //   setUserInfo({ ...userInfo, [e.target.name]: e.target.value })
  // }



  // const addBroadcaster = (data) => {
  //   console.log(data, 'input data')
  //   broadcasterSignup({
  //     variables: {
  //       zipcode: userInfo.zipcode,
  //       state: userInfo.state,
  //       radioStationWebsite: userInfo.radioStationWebsite,
  //       radioStationDigitalStreaUrl: userInfo.radioStationDigitalStreaUrl,
  //       radioStationCategory: userInfo.radioStationCategory,
  //       positionHeld: userInfo.positionHeld,
  //       phoneNumber: userInfo.phoneNumber,
  //       password: userInfo.password,
  //       name: userInfo.name,
  //       fullname: userInfo.fullname,
  //       email: userInfo.email,
  //       country: userInfo.country,
  //       confirmPassword: userInfo.confirmPassword,
  //       city: userInfo.city,
  //       address: userInfo.address
  //     },
  //     onCompleted: (data) => {
  //       localStorage.setItem(AUTH_TOKEN, data.auth.broadcasterRegister.token);
  //       setTimeout(navigate("/dashboard/vendor"), 10000)
  //     }
  //   })
  // }
  // const loginPassword = useRef();
  // const checkMark = useRef();

  // useEffect(() => {
  //   checkMark.current.style.display = "none";
  // }, [checkMark]);

  return (
    <section id="signup-vendor">
      <header>
        <nav>
          <Link to="/">
            <svg
              id="s-logo-sm"
              width="122"
              height="28"
              viewBox="0 0 122 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M74.4913 14.9712C74.2184 14.6967 73.9083 14.4618 73.57 14.2735C73.2664 14.1203 72.9298 14.0438 72.5898 14.0508C72.3086 14.0532 72.0357 14.1461 71.8115 14.3156C71.6942 14.3881 71.5977 14.4896 71.5314 14.6104C71.465 14.7312 71.4311 14.8671 71.4329 15.0048C71.4329 15.4251 71.7105 15.7319 72.2743 15.9673C72.838 16.2026 73.4143 16.4464 74.0075 16.6691C74.6093 16.9123 75.1433 17.2973 75.564 17.7913C76.0354 18.4548 76.2601 19.262 76.1993 20.0734C76.2322 20.6183 76.1458 21.1639 75.946 21.672C75.7461 22.1801 75.4378 22.6385 75.0424 23.0153C74.2304 23.726 73.1761 24.0977 72.0976 24.0534C71.4172 24.0552 70.7419 23.9356 70.1035 23.7004C69.4649 23.4716 68.8825 23.109 68.3955 22.6371L69.9815 20.3718C70.2164 20.682 70.5224 20.9313 70.8738 21.0988C71.2252 21.2663 71.6117 21.347 72.0008 21.3342C72.3387 21.3323 72.6695 21.2362 72.9558 21.0568C73.0959 20.9806 73.2124 20.8674 73.2925 20.7296C73.3727 20.5917 73.4133 20.4345 73.4101 20.2751C73.4101 19.8548 73.1325 19.5396 72.5687 19.2959L71.7063 18.9344L71.2856 18.7579L70.8355 18.5688C70.2378 18.3247 69.708 17.9397 69.2916 17.4466C68.8173 16.8081 68.5889 16.0205 68.6479 15.2276C68.6318 14.5272 68.8252 13.838 69.2032 13.2481C69.5631 12.7052 70.0631 12.2696 70.6504 11.9872C71.2366 11.7005 71.8825 11.5565 72.5351 11.5669C73.1606 11.5677 73.7802 11.6876 74.3609 11.92C74.9436 12.1554 75.4772 12.4975 75.9342 12.9286L74.4913 14.9712Z"
                fill="#004643"
              />
              <path
                d="M76.6251 19.3801C76.6182 18.5566 76.8553 17.7494 77.3067 17.0602C77.7578 16.3565 78.3879 15.785 79.1325 15.4043C79.7918 15.0539 80.5189 14.8493 81.2643 14.8044C82.0098 14.7594 82.7562 14.8751 83.4529 15.1437C84.0456 15.3569 84.5893 15.6871 85.0515 16.1146C85.5136 16.5304 85.887 17.0349 86.1495 17.5981C86.4101 18.1754 86.5449 18.8015 86.5449 19.4348C86.5449 20.068 86.4101 20.6941 86.1495 21.2714C85.8916 21.8338 85.5207 22.3374 85.0599 22.7508C84.5966 23.1768 84.0532 23.5068 83.4613 23.7216C82.2517 24.1588 80.9269 24.1588 79.7172 23.7216L79.5237 23.6376L79.3302 23.5535V27.1175H76.6336L76.6251 19.3801ZM79.3218 19.439C79.3161 19.8232 79.4244 20.2005 79.6331 20.5233C79.8413 20.8542 80.131 21.1262 80.4744 21.3134C80.8101 21.5095 81.192 21.6125 81.5809 21.6118C81.9723 21.612 82.3568 21.5091 82.6957 21.3134C83.0399 21.1273 83.3299 20.855 83.5371 20.5233C83.7461 20.2004 83.8558 19.8235 83.8526 19.439C83.8534 19.1477 83.7937 18.8594 83.6771 18.5924C83.5605 18.3255 83.3896 18.0856 83.1753 17.8881C82.7531 17.4708 82.1832 17.2367 81.5893 17.2367C80.9954 17.2367 80.4254 17.4708 80.0033 17.8881C79.787 18.0843 79.6145 18.3238 79.497 18.591C79.3796 18.8582 79.3199 19.1471 79.3218 19.439Z"
                fill="#004643"
              />
              <path
                d="M91.3992 17.3965C91.2338 17.2934 91.043 17.2381 90.8481 17.2367H90.6966C90.0109 17.2662 89.666 17.7411 89.666 18.6699V23.7889H86.9609V18.6699C86.9609 16.2127 88.2062 14.921 90.6966 14.7949H90.8481C91.0374 14.7949 91.2183 14.8201 91.3992 14.8454V17.3965Z"
                fill="#004643"
              />
              <path
                d="M100.945 17.5809C101.198 18.1555 101.327 18.7771 101.324 19.4049V19.4806C101.326 19.5114 101.326 19.5422 101.324 19.573V19.7201H100.251H94.4454C94.5231 20.2342 94.7811 20.704 95.1732 21.0458C95.5654 21.3875 96.0663 21.579 96.5867 21.5862C96.8704 21.5887 97.1517 21.5329 97.4129 21.4223C97.6742 21.3117 97.9099 21.1486 98.1054 20.9431L98.1306 20.9095L98.1559 20.8843V20.8591L99.6073 22.9353C99.2274 23.2532 98.802 23.5124 98.3452 23.7044C97.7745 23.9288 97.1664 24.0429 96.5531 24.0406C95.7399 24.0404 94.9404 23.832 94.2309 23.4354C93.509 23.0395 92.9051 22.4594 92.4808 21.7543C92.101 21.1271 91.8776 20.4181 91.8293 19.6867C91.781 18.9553 91.9092 18.223 92.2031 17.5515C92.4498 16.997 92.8061 16.4981 93.2507 16.0847C93.6895 15.6643 94.207 15.3344 94.7735 15.1139C95.928 14.66 97.2118 14.66 98.3662 15.1139C98.9435 15.3377 99.4699 15.675 99.9144 16.1057C100.355 16.5219 100.706 17.024 100.945 17.5809ZM96.5741 17.2447C96.2002 17.2437 95.8328 17.3424 95.5097 17.5305C95.2845 17.6719 95.0841 17.8494 94.9166 18.0558H98.2316C98.0605 17.8588 97.8605 17.6887 97.6384 17.5515C97.317 17.357 96.9498 17.2511 96.5741 17.2447Z"
                fill="#004643"
              />
              <path
                d="M122 19.4812C122.002 20.3059 121.764 21.1134 121.314 21.8053C120.863 22.5089 120.233 23.0803 119.488 23.4612C118.828 23.809 118.101 24.0121 117.356 24.057C116.611 24.1019 115.865 23.9877 115.168 23.7218C114.577 23.5043 114.035 23.1746 113.569 22.751C113.109 22.3323 112.736 21.8266 112.471 21.2632C112.213 20.6852 112.08 20.0594 112.08 19.4266C112.08 18.7937 112.213 18.1679 112.471 17.5899C112.73 17.029 113.101 16.5269 113.561 16.1148C114.027 15.6922 114.57 15.3626 115.16 15.1439C115.761 14.9235 116.396 14.8111 117.036 14.8119C117.673 14.8108 118.306 14.9232 118.904 15.1439L119.101 15.228L119.295 15.312V11.748H121.992L122 19.4812ZM119.303 19.4223C119.306 19.0393 119.196 18.6638 118.988 18.3422C118.78 18.0098 118.49 17.7363 118.146 17.5479C117.809 17.3531 117.426 17.2516 117.036 17.2537C116.645 17.2526 116.26 17.3541 115.921 17.5479C115.578 17.7379 115.289 18.0111 115.08 18.3422C114.874 18.6651 114.766 19.04 114.768 19.4223C114.765 19.7147 114.823 20.0044 114.94 20.2724C115.057 20.5405 115.229 20.7807 115.446 20.9774C115.867 21.3944 116.436 21.6284 117.03 21.6284C117.623 21.6284 118.192 21.3944 118.613 20.9774C118.831 20.7805 119.004 20.5405 119.123 20.2727C119.242 20.0049 119.303 19.7153 119.303 19.4223Z"
                fill="#004643"
              />
              <path
                d="M111.272 17.5724C111.007 17.0152 110.634 16.5165 110.174 16.1056C109.713 15.6819 109.173 15.3547 108.584 15.1432C107.984 14.9178 107.348 14.8025 106.707 14.8027C106.066 14.8022 105.43 14.9205 104.831 15.1516C104.243 15.3614 103.703 15.6856 103.241 16.1056C102.782 16.5161 102.412 17.015 102.151 17.5724C101.848 18.242 101.715 18.9763 101.765 19.7097C101.815 20.4431 102.046 21.1527 102.437 21.7752C102.889 22.4763 103.515 23.0482 104.255 23.4353C105.008 23.8308 105.848 24.0357 106.699 24.0321C107.34 24.0349 107.977 23.918 108.575 23.6874C108.659 23.658 108.794 23.6076 108.971 23.5277V23.7799L111.65 23.8051V19.4048C111.66 18.7737 111.53 18.1483 111.272 17.5724ZM108.975 19.2073C109.04 19.77 108.896 20.3371 108.571 20.8012C108.246 21.2654 107.762 21.5943 107.21 21.7258C106.659 21.8573 106.078 21.7822 105.578 21.5148C105.078 21.2474 104.694 20.8062 104.497 20.2747C104.301 19.7432 104.307 19.1582 104.513 18.6305C104.72 18.1029 105.112 17.6691 105.617 17.4113C106.122 17.1534 106.704 17.0895 107.253 17.2315C107.802 17.3734 108.28 17.7116 108.596 18.1818L109.467 18.0935L108.895 18.7996C108.931 18.9331 108.955 19.0695 108.966 19.2073H108.975Z"
                fill="#004643"
              />
              <path
                d="M0 23.8553V11.8017H3.36551C4.22163 11.7886 5.07417 11.915 5.88964 12.1758C6.56296 12.39 7.15169 12.8105 7.57239 13.3777C7.95445 13.9745 8.1641 14.6651 8.17821 15.3733C8.19231 16.0816 8.01032 16.78 7.65232 17.3914C7.34445 17.9422 6.85549 18.3698 6.26825 18.6018L9.27197 23.8805H5.80129L3.00371 18.4631H2.97006V23.8553H0ZM2.97006 17.7403C3.39811 17.7547 3.82536 17.6935 4.23212 17.5595C4.53987 17.4583 4.80588 17.2589 4.98936 16.9922C5.17741 16.6901 5.27114 16.339 5.2586 15.9835C5.28436 15.7423 5.24577 15.4986 5.14676 15.2772C5.04775 15.0557 4.89185 14.8644 4.69488 14.7226C4.17248 14.4668 3.59299 14.3496 3.01213 14.3822L2.97006 17.7403Z"
                fill="#004643"
              />
              <path
                d="M15.9227 23.6372C15.7418 23.7171 15.6072 23.7675 15.502 23.7969C14.8971 24.0309 14.2535 24.1492 13.6047 24.1458C12.7486 24.1478 11.905 23.94 11.1479 23.5405C10.3963 23.1519 9.76073 22.572 9.3053 21.8594C8.91679 21.229 8.68974 20.5127 8.64425 19.7738C8.59876 19.0349 8.73622 18.2962 9.04448 17.623C9.30698 17.0598 9.68041 16.5552 10.1425 16.1394C10.609 15.7175 11.1535 15.3906 11.7453 15.177C12.3491 14.9442 12.9911 14.8259 13.6384 14.8282C14.2861 14.8275 14.9287 14.9428 15.5357 15.1686C16.1281 15.3849 16.6726 15.7147 17.1385 16.1394C17.6028 16.5551 17.9791 17.0596 18.2449 17.623C18.5147 18.2034 18.6512 18.8366 18.6446 19.4764V23.9188L15.9438 23.8894L15.9227 23.6372ZM11.6654 18.3921C11.4573 18.7201 11.3478 19.1008 11.3498 19.4891C11.3484 19.7821 11.4082 20.0721 11.5256 20.3407C11.643 20.6092 11.8152 20.8502 12.0314 21.0483C12.4606 21.4635 13.0346 21.6956 13.6321 21.6956C14.2295 21.6956 14.8036 21.4635 15.2328 21.0483C15.4489 20.8502 15.6212 20.6092 15.7386 20.3407C15.8559 20.0721 15.9158 19.7821 15.9143 19.4891C15.9163 19.1008 15.8068 18.7201 15.5988 18.3921C15.3902 18.0583 15.1009 17.7822 14.7574 17.5894C14.4136 17.4018 14.0281 17.3035 13.6363 17.3035C13.2445 17.3035 12.859 17.4018 12.5152 17.5894C12.1686 17.7811 11.8763 18.0572 11.6654 18.3921Z"
                fill="#004643"
              />
              <path
                d="M29.0658 19.5487C29.0667 20.3798 28.8255 21.1932 28.3717 21.8897C27.9186 22.6042 27.2824 23.1847 26.5291 23.5708C25.8644 23.9231 25.1318 24.129 24.3808 24.1747C23.6297 24.2204 22.8775 24.1047 22.175 23.8356C21.5784 23.6188 21.0307 23.286 20.5637 22.8563C20.0941 22.4296 19.7163 21.912 19.4531 21.3349C19.1924 20.7525 19.0576 20.1216 19.0576 19.4836C19.0576 18.8456 19.1924 18.2147 19.4531 17.6323C19.7129 17.0639 20.0882 16.5558 20.5553 16.1403C21.0231 15.7117 21.5705 15.379 22.1666 15.161C23.3893 14.7161 24.73 14.7161 25.9527 15.161L26.1463 15.2451L26.344 15.3291V11.7441H29.0658V19.5487ZM26.344 19.4899C26.3476 19.1027 26.2379 18.7229 26.0285 18.3972C25.8199 18.0633 25.5305 17.7872 25.1871 17.5944C24.8481 17.3995 24.4634 17.298 24.0723 17.3002C23.6772 17.2985 23.2886 17.3999 22.9448 17.5944C22.5957 17.7838 22.3005 18.0585 22.0866 18.393C21.8772 18.7187 21.7675 19.0985 21.7711 19.4857C21.7696 19.7797 21.8298 20.0708 21.9479 20.3401C22.066 20.6094 22.2394 20.851 22.4568 21.0491C22.8811 21.4721 23.4561 21.7096 24.0554 21.7096C24.6548 21.7096 25.2298 21.4721 25.6541 21.0491C25.8717 20.8518 26.0456 20.6111 26.1644 20.3425C26.2832 20.074 26.3444 19.7835 26.344 19.4899Z"
                fill="#004643"
              />
              <path
                d="M51.5553 21.7545H46.7216L45.5353 23.8979H42.4727L49.1406 11.6426L55.8043 23.8979H52.7417L51.5553 21.7545ZM50.3269 19.5228L49.1406 17.3752L47.95 19.5228H50.3269Z"
                fill="#004643"
              />
              <path
                d="M65.1135 19.5487C65.1179 20.3797 64.878 21.1937 64.4236 21.8897C63.9705 22.6042 63.3343 23.1847 62.581 23.5708C61.9156 23.9232 61.1823 24.1291 60.4305 24.1748C59.6788 24.2205 58.9259 24.1048 58.2227 23.8356C57.627 23.6169 57.0797 23.2843 56.6114 22.8563C56.1444 22.4283 55.7681 21.9109 55.505 21.3349C55.2417 20.7532 55.1055 20.122 55.1055 19.4836C55.1055 18.8451 55.2417 18.214 55.505 17.6323C55.7647 17.0649 56.1385 16.5571 56.603 16.1403C57.0714 15.7125 57.6187 15.3799 58.2143 15.161C59.437 14.7161 60.7777 14.7161 62.0005 15.161L62.1982 15.2451L62.3959 15.3291V11.7441H65.1135V19.5487ZM62.3959 19.4899C62.3982 19.1023 62.2871 18.7225 62.0762 18.3972C61.8679 18.0631 61.5785 17.7869 61.2348 17.5944C60.8943 17.3995 60.5082 17.298 60.1158 17.3002C59.722 17.2985 59.3347 17.3999 58.9925 17.5944C58.6491 17.7872 58.3597 18.0633 58.1512 18.3972C57.9417 18.7229 57.832 19.1027 57.8356 19.4899C57.8336 19.7836 57.8931 20.0745 58.0105 20.3438C58.1279 20.6131 58.3005 20.8548 58.5172 21.0533C58.9415 21.4763 59.5164 21.7138 60.1158 21.7138C60.7152 21.7138 61.2901 21.4763 61.7144 21.0533C61.931 20.8548 62.1036 20.6131 62.221 20.3438C62.3384 20.0745 62.398 19.7836 62.3959 19.4899Z"
                fill="#004643"
              />
              <path
                d="M42.3508 17.6238C42.089 17.0542 41.714 16.5437 41.2486 16.1234C40.7815 15.6959 40.2337 15.3659 39.6373 15.1525C38.4165 14.6966 37.072 14.6966 35.8512 15.1525C35.2566 15.3735 34.7097 15.7058 34.2399 16.1318C33.7754 16.5496 33.4005 17.0572 33.1377 17.6238C32.8341 18.3038 32.7033 19.0483 32.7568 19.791C32.8104 20.5337 33.0467 21.2517 33.4448 21.8812C33.8998 22.5942 34.5355 23.1742 35.2874 23.5623C35.9529 23.9147 36.6861 24.1207 37.4379 24.1663C38.1896 24.212 38.9425 24.0963 39.6458 23.8271C40.2404 23.6062 40.7872 23.2738 41.257 22.8478C41.7238 22.4238 42.0989 21.909 42.3592 21.3348C42.6199 20.7509 42.7546 20.1187 42.7546 19.4793C42.7546 18.8399 42.6199 18.2077 42.3592 17.6238H42.3508ZM40.0412 19.2082C40.1093 19.7739 39.9674 20.345 39.6424 20.8132C39.3174 21.2814 38.8318 21.6143 38.2775 21.7488C37.7233 21.8834 37.1389 21.8102 36.6351 21.5432C36.1312 21.2761 35.7429 20.8338 35.5436 20.2999C35.3443 19.766 35.3479 19.1777 35.5536 18.6463C35.7594 18.1148 36.1531 17.6773 36.6602 17.4164C37.1672 17.1555 37.7524 17.0894 38.305 17.2306C38.8575 17.3719 39.3391 17.7106 39.6584 18.1827L40.525 18.0945L39.9529 18.8006C39.9946 18.9335 40.0242 19.0699 40.0412 19.2082Z"
                fill="#004643"
              />
              <path
                d="M31.5302 5.44336L32.3127 23.9104H29.5908L30.3733 5.45176L31.5302 5.44336Z"
                fill="#FC9732"
              />
              <path
                d="M29.2035 22.7715H32.6027C32.9051 22.7715 33.195 22.8915 33.4089 23.1051C33.6227 23.3187 33.7428 23.6084 33.7428 23.9104H28.0635C28.0635 23.6084 28.1836 23.3187 28.3974 23.1051C28.6112 22.8915 28.9012 22.7715 29.2035 22.7715Z"
                fill="#FC9732"
              />
              <path
                d="M30.9207 2.85329e-05C30.1877 -0.00222964 29.4615 0.140727 28.7842 0.420629C28.1069 0.70053 27.4918 1.11182 26.9746 1.63071L27.6393 2.29896C28.0694 1.86628 28.581 1.52288 29.1445 1.28855C29.708 1.05422 30.3124 0.933577 30.9228 0.933577C31.5332 0.933577 32.1375 1.05422 32.701 1.28855C33.2646 1.52288 33.7761 1.86628 34.2062 2.29896L34.8709 1.63071C34.3529 1.11169 33.7372 0.700359 33.0591 0.420467C32.3811 0.140576 31.6543 -0.00232199 30.9207 2.85329e-05Z"
                fill="#FC9732"
              />
              <path
                d="M28.2861 2.96326L28.955 3.63151C29.211 3.37545 29.5149 3.17231 29.8495 3.03371C30.1841 2.89511 30.5427 2.82377 30.9049 2.82377C31.2671 2.82377 31.6258 2.89511 31.9604 3.03371C32.2949 3.17231 32.5989 3.37545 32.8548 3.63151L33.5195 2.96326C32.8248 2.27135 31.8838 1.88281 30.9028 1.88281C29.9218 1.88281 28.9809 2.27135 28.2861 2.96326Z"
                fill="#FC9732"
              />
              <path
                d="M30.9039 5.05982C31.429 5.05982 31.8546 4.63457 31.8546 4.10999C31.8546 3.58541 31.429 3.16016 30.9039 3.16016C30.3788 3.16016 29.9531 3.58541 29.9531 4.10999C29.9531 4.63457 30.3788 5.05982 30.9039 5.05982Z"
                fill="#FC9732"
              />
            </svg>

            <svg
              id="s-logo-lg"
              width="226"
              height="49"
              viewBox="0 0 226 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M137.554 27.051C137.05 26.5548 136.478 26.1305 135.853 25.7903C135.292 25.5133 134.671 25.3751 134.043 25.3878C133.524 25.3921 133.02 25.5599 132.606 25.8662C132.389 25.9972 132.211 26.1808 132.089 26.399C131.966 26.6173 131.903 26.8628 131.907 27.1117C131.907 27.8712 132.419 28.4256 133.46 28.8508C134.501 29.2761 135.566 29.7166 136.661 30.1191C137.772 30.5586 138.758 31.2543 139.535 32.1469C140.406 33.3459 140.82 34.8045 140.708 36.2707C140.769 37.2554 140.609 38.2412 140.24 39.1593C139.871 40.0775 139.302 40.9059 138.572 41.5868C137.073 42.871 135.126 43.5426 133.134 43.4627C131.878 43.4658 130.631 43.2498 129.452 42.8247C128.272 42.4112 127.197 41.7561 126.298 40.9033L129.227 36.8099C129.66 37.3704 130.225 37.821 130.874 38.1236C131.523 38.4263 132.237 38.5722 132.955 38.549C133.579 38.5455 134.19 38.3719 134.719 38.0478C134.978 37.9101 135.193 37.7055 135.341 37.4564C135.489 37.2073 135.564 36.9232 135.558 36.6352C135.558 35.8758 135.045 35.3062 134.004 34.8657L132.412 34.2126L131.635 33.8936L130.804 33.5518C129.7 33.1108 128.722 32.4151 127.953 31.5241C127.077 30.3703 126.655 28.947 126.764 27.5142C126.734 26.2487 127.091 25.0033 127.789 23.9372C128.454 22.9563 129.377 22.1691 130.462 21.6588C131.544 21.1407 132.737 20.8805 133.942 20.8994C135.097 20.9009 136.241 21.1174 137.313 21.5373C138.389 21.9628 139.375 22.5809 140.219 23.36L137.554 27.051Z"
                fill="#004643"
              />
              <path
                d="M141.492 35.018C141.48 33.5298 141.918 32.0712 142.751 30.8258C143.584 29.5542 144.747 28.5216 146.122 27.8336C147.34 27.2005 148.683 26.8308 150.059 26.7495C151.436 26.6683 152.814 26.8774 154.101 27.3627C155.195 27.748 156.199 28.3446 157.052 29.117C157.906 29.8684 158.595 30.7802 159.08 31.7979C159.561 32.8411 159.81 33.9723 159.81 35.1167C159.81 36.261 159.561 37.3923 159.08 38.4355C158.604 39.4519 157.919 40.3617 157.068 41.1088C156.212 41.8786 155.209 42.4749 154.116 42.8631C151.882 43.6532 149.436 43.6532 147.202 42.8631L146.845 42.7112L146.488 42.5593V48.9995H141.508L141.492 35.018ZM146.472 35.1243C146.462 35.8186 146.662 36.5004 147.047 37.0837C147.431 37.6816 147.966 38.1732 148.601 38.5114C149.22 38.8658 149.926 39.052 150.644 39.0506C151.366 39.051 152.076 38.865 152.702 38.5114C153.338 38.1751 153.873 37.6831 154.256 37.0837C154.642 36.5003 154.844 35.819 154.839 35.1243C154.84 34.5979 154.73 34.077 154.514 33.5946C154.299 33.1122 153.984 32.6788 153.588 32.3219C152.808 31.5678 151.756 31.1448 150.659 31.1448C149.562 31.1448 148.51 31.5678 147.73 32.3219C147.331 32.6763 147.012 33.1091 146.796 33.5919C146.579 34.0748 146.469 34.5969 146.472 35.1243Z"
                fill="#004643"
              />
              <path
                d="M168.775 31.4334C168.469 31.2472 168.117 31.1474 167.757 31.1448H167.477C166.211 31.198 165.574 32.0562 165.574 33.7346V42.9847H160.579V33.7346C160.579 29.2943 162.879 26.9603 167.477 26.7324H167.757C168.107 26.7324 168.441 26.778 168.775 26.8236V31.4334Z"
                fill="#004643"
              />
              <path
                d="M186.402 31.7665C186.87 32.8048 187.108 33.928 187.101 35.0625V35.1992C187.105 35.2548 187.105 35.3106 187.101 35.3663V35.6321H185.12H174.4C174.544 36.561 175.02 37.41 175.744 38.0275C176.468 38.6451 177.393 38.9912 178.354 39.004C178.878 39.0086 179.397 38.9079 179.88 38.708C180.362 38.5081 180.797 38.2134 181.159 37.8421L181.205 37.7813L181.252 37.7358V37.6902L183.932 41.4419C183.23 42.0164 182.445 42.4848 181.601 42.8317C180.548 43.2373 179.424 43.4434 178.292 43.4392C176.79 43.4389 175.314 43.0624 174.004 42.3456C172.671 41.6302 171.556 40.582 170.772 39.3078C170.071 38.1746 169.658 36.8933 169.569 35.5717C169.48 34.2501 169.717 32.9268 170.26 31.7133C170.715 30.7114 171.373 29.8098 172.194 29.0628C173.004 28.3031 173.96 27.707 175.006 27.3085C177.138 26.4883 179.508 26.4883 181.64 27.3085C182.706 27.7129 183.678 28.3224 184.499 29.1008C185.313 29.8529 185.961 30.7601 186.402 31.7665ZM178.331 31.1589C177.64 31.1572 176.962 31.3355 176.365 31.6754C175.95 31.931 175.58 32.2517 175.27 32.6247H181.392C181.076 32.2686 180.706 31.9613 180.296 31.7133C179.703 31.3619 179.025 31.1706 178.331 31.1589Z"
                fill="#004643"
              />
              <path
                d="M225.282 35.2005C225.285 36.6908 224.845 38.1499 224.016 39.4002C223.182 40.6716 222.019 41.7041 220.644 42.3925C219.425 43.0208 218.083 43.3878 216.707 43.469C215.331 43.5502 213.954 43.3437 212.666 42.8633C211.575 42.4703 210.573 41.8746 209.714 41.109C208.863 40.3524 208.174 39.4387 207.686 38.4205C207.21 37.3761 206.964 36.2453 206.964 35.1017C206.964 33.9581 207.21 32.8274 207.686 31.7829C208.164 30.7693 208.849 29.8621 209.698 29.1173C210.559 28.3537 211.561 27.7582 212.65 27.3629C213.76 26.9647 214.933 26.7616 216.115 26.763C217.292 26.761 218.46 26.9642 219.564 27.3629L219.929 27.5148L220.287 27.6667V21.2266H225.266L225.282 35.2005ZM220.302 35.0941C220.307 34.4019 220.105 33.7234 219.72 33.1423C219.336 32.5416 218.801 32.0474 218.166 31.707C217.543 31.355 216.835 31.1715 216.115 31.1754C215.393 31.1734 214.683 31.3567 214.057 31.707C213.424 32.0504 212.889 32.544 212.503 33.1423C212.124 33.7257 211.924 34.4031 211.928 35.0941C211.921 35.6223 212.03 36.1459 212.245 36.6302C212.461 37.1146 212.779 37.5488 213.179 37.9041C213.957 38.6577 215.008 39.0805 216.103 39.0805C217.199 39.0805 218.25 38.6577 219.028 37.9041C219.429 37.5484 219.75 37.1147 219.969 36.6308C220.189 36.1469 220.302 35.6235 220.302 35.0941Z"
                fill="#004643"
              />
              <path
                d="M205.472 31.7528C204.984 30.746 204.295 29.8448 203.445 29.1024C202.595 28.3367 201.596 27.7454 200.508 27.3632C199.401 26.956 198.227 26.7477 197.044 26.7481C195.859 26.747 194.684 26.9608 193.579 27.3784C192.493 27.7575 191.495 28.3435 190.643 29.1024C189.796 29.8441 189.111 30.7457 188.631 31.7528C188.07 32.9629 187.825 34.2898 187.917 35.6151C188.01 36.9403 188.436 38.2225 189.159 39.3473C189.993 40.6142 191.149 41.6478 192.515 42.3472C193.906 43.0619 195.456 43.4321 197.028 43.4256C198.213 43.4306 199.388 43.2194 200.493 42.8028C200.648 42.7497 200.897 42.6585 201.223 42.5143V42.9699L206.171 43.0155V35.064C206.188 33.9237 205.95 32.7935 205.472 31.7528ZM201.231 34.7071C201.351 35.724 201.086 36.7488 200.485 37.5874C199.885 38.4261 198.991 39.0205 197.972 39.2581C196.954 39.4957 195.881 39.3601 194.958 38.8768C194.035 38.3936 193.325 37.5964 192.963 36.6359C192.6 35.6755 192.611 34.6185 192.992 33.665C193.373 32.7114 194.098 31.9276 195.031 31.4617C195.963 30.9958 197.038 30.8802 198.052 31.1368C199.065 31.3934 199.948 32.0043 200.532 32.854L202.14 32.6946L201.083 33.9704C201.15 34.2116 201.194 34.4582 201.215 34.7071H201.231Z"
                fill="#004643"
              />
              <path
                d="M0 43.105V21.3239H6.21469C7.79559 21.3003 9.36988 21.5286 10.8757 21.9998C12.119 22.387 13.2062 23.1469 13.983 24.1719C14.6886 25.2502 15.0757 26.4981 15.1017 27.7779C15.1278 29.0577 14.7917 30.3197 14.1306 31.4246C13.5621 32.42 12.6592 33.1926 11.5749 33.6118L17.1215 43.1505H10.7126L5.54661 33.3612H5.48446V43.105H0ZM5.48446 32.055C6.2749 32.081 7.06384 31.9705 7.81497 31.7284C8.38325 31.5454 8.87446 31.1853 9.21327 30.7031C9.56052 30.1574 9.7336 29.5228 9.71045 28.8805C9.75801 28.4447 9.68675 28.0044 9.50393 27.6042C9.3211 27.204 9.03321 26.8583 8.66949 26.6021C7.70484 26.1398 6.63475 25.928 5.56214 25.987L5.48446 32.055Z"
                fill="#004643"
              />
              <path
                d="M29.4023 42.7111C29.0683 42.8554 28.8197 42.9465 28.6255 42.9997C27.5083 43.4225 26.3199 43.6363 25.122 43.6301C23.541 43.6338 21.9832 43.2583 20.5852 42.5364C19.1974 41.8342 18.0237 40.7863 17.1827 39.4986C16.4653 38.3595 16.046 37.0651 15.962 35.7299C15.878 34.3947 16.1318 33.0599 16.7011 31.8434C17.1858 30.8256 17.8754 29.9139 18.7286 29.1625C19.5901 28.4001 20.5954 27.8094 21.6883 27.4234C22.8034 27.0027 23.9889 26.789 25.1841 26.793C26.3801 26.7919 27.5667 27.0002 28.6876 27.4082C29.7816 27.7991 30.787 28.395 31.6474 29.1625C32.5048 29.9137 33.1995 30.8253 33.6905 31.8434C34.1886 32.8921 34.4407 34.0364 34.4284 35.1926V43.2199L29.4412 43.1668L29.4023 42.7111ZM21.5407 33.2332C21.1566 33.8258 20.9544 34.5137 20.9581 35.2153C20.9554 35.7448 21.0659 36.269 21.2826 36.7542C21.4994 37.2394 21.8175 37.675 22.2166 38.0329C23.0092 38.7832 24.0692 39.2026 25.1724 39.2026C26.2757 39.2026 27.3357 38.7832 28.1283 38.0329C28.5274 37.675 28.8455 37.2394 29.0623 36.7542C29.279 36.269 29.3895 35.7448 29.3868 35.2153C29.3905 34.5137 29.1883 33.8258 28.8042 33.2332C28.419 32.6299 27.8847 32.131 27.2505 31.7826C26.6155 31.4436 25.9037 31.266 25.1802 31.266C24.4568 31.266 23.7449 31.4436 23.1099 31.7826C22.4701 32.129 21.9303 32.628 21.5407 33.2332Z"
                fill="#004643"
              />
              <path
                d="M53.6714 35.3218C53.6731 36.8235 53.2277 38.2933 52.3897 39.5519C51.553 40.8431 50.3782 41.892 48.9871 42.5897C47.7597 43.2263 46.407 43.5984 45.02 43.6809C43.6331 43.7634 42.2442 43.5545 40.9469 43.0681C39.8452 42.6765 38.8339 42.075 37.9716 41.2986C37.1044 40.5276 36.4067 39.5923 35.9208 38.5494C35.4393 37.4969 35.1904 36.357 35.1904 35.204C35.1904 34.0511 35.4393 32.9111 35.9208 31.8587C36.4004 30.8316 37.0936 29.9134 37.9561 29.1626C38.8198 28.3881 39.8307 27.7869 40.9313 27.3931C43.1893 26.5892 45.6649 26.5892 47.9229 27.3931L48.2802 27.545L48.6453 27.6969V21.2188H53.6714V35.3218ZM48.6453 35.2154C48.652 34.5158 48.4495 33.8295 48.0627 33.2409C47.6775 32.6375 47.1432 32.1387 46.509 31.7903C45.8831 31.438 45.1727 31.2546 44.4504 31.2587C43.7209 31.2556 43.0032 31.4388 42.3685 31.7903C41.7239 32.1325 41.1786 32.6289 40.7837 33.2333C40.397 33.8219 40.1945 34.5082 40.2011 35.2078C40.1983 35.7391 40.3095 36.2651 40.5276 36.7518C40.7457 37.2384 41.0659 37.6749 41.4674 38.033C42.2509 38.7973 43.3125 39.2265 44.4193 39.2265C45.5262 39.2265 46.5878 38.7973 47.3713 38.033C47.7732 37.6764 48.0943 37.2415 48.3137 36.7562C48.5331 36.2709 48.6461 35.746 48.6453 35.2154Z"
                fill="#004643"
              />
              <path
                d="M95.2016 39.3075H86.2757L84.0851 43.1807H78.4297L90.7425 21.0352L103.048 43.1807H97.3922L95.2016 39.3075ZM92.9332 35.2749L90.7425 31.3941L88.5441 35.2749H92.9332Z"
                fill="#004643"
              />
              <path
                d="M120.239 35.3218C120.247 36.8233 119.804 38.2942 118.965 39.5519C118.128 40.8431 116.953 41.892 115.562 42.5897C114.333 43.2265 112.979 43.5986 111.591 43.6811C110.203 43.7637 108.813 43.5546 107.514 43.0681C106.414 42.6731 105.403 42.072 104.539 41.2986C103.676 40.5252 102.981 39.5903 102.496 38.5494C102.009 37.4982 101.758 36.3577 101.758 35.204C101.758 34.0503 102.009 32.9099 102.496 31.8587C102.975 30.8335 103.665 29.9158 104.523 29.1626C105.388 28.3897 106.399 27.7887 107.498 27.3931C109.756 26.5892 112.232 26.5892 114.49 27.3931L114.855 27.545L115.22 27.6969V21.2188H120.239V35.3218ZM115.22 35.2154C115.225 34.515 115.019 33.8287 114.63 33.2409C114.245 32.6371 113.711 32.1382 113.076 31.7903C112.447 31.4381 111.734 31.2547 111.01 31.2587C110.283 31.2555 109.568 31.4388 108.936 31.7903C108.301 32.1387 107.767 32.6375 107.382 33.2409C106.995 33.8295 106.793 34.5158 106.799 35.2154C106.795 35.7462 106.905 36.2718 107.122 36.7584C107.339 37.245 107.658 37.6818 108.058 38.0406C108.841 38.8049 109.903 39.2341 111.01 39.2341C112.117 39.2341 113.178 38.8049 113.962 38.0406C114.362 37.6818 114.68 37.245 114.897 36.7584C115.114 36.2718 115.224 35.7462 115.22 35.2154Z"
                fill="#004643"
              />
              <path
                d="M78.2045 31.8433C77.721 30.814 77.0286 29.8916 76.1692 29.1321C75.3066 28.3597 74.2951 27.7632 73.1939 27.3777C70.9396 26.5538 68.4567 26.5538 66.2024 27.3777C65.1044 27.777 64.0945 28.3776 63.2271 29.1473C62.3693 29.9023 61.677 30.8195 61.1918 31.8433C60.6311 33.0722 60.3895 34.4174 60.4884 35.7594C60.5873 37.1015 61.0237 38.399 61.7588 39.5365C62.599 40.8249 63.7729 41.873 65.1614 42.5743C66.3902 43.2111 67.7442 43.5833 69.1324 43.6658C70.5206 43.7483 71.9107 43.5393 73.2094 43.0528C74.3074 42.6536 75.3172 42.053 76.1847 41.2833C77.0467 40.5171 77.7393 39.5867 78.22 38.5493C78.7013 37.4941 78.9501 36.3516 78.9501 35.1963C78.9501 34.0409 78.7013 32.8985 78.22 31.8433H78.2045ZM73.9396 34.7064C74.0654 35.7287 73.8034 36.7606 73.2032 37.6066C72.603 38.4527 71.7063 39.0543 70.6828 39.2973C69.6594 39.5404 68.5803 39.4082 67.6499 38.9257C66.7196 38.4432 66.0024 37.6439 65.6344 36.6792C65.2664 35.7144 65.273 34.6513 65.653 33.691C66.0329 32.7307 66.7599 31.94 67.6962 31.4685C68.6326 30.9971 69.7132 30.8777 70.7335 31.1329C71.7539 31.3881 72.6431 32.0002 73.2327 32.8534L74.833 32.6939L73.7765 33.9698C73.8536 34.21 73.9082 34.4566 73.9396 34.7064Z"
                fill="#004643"
              />
              <path
                d="M58.2228 9.83594L59.6677 43.2062H54.6416L56.0865 9.85113L58.2228 9.83594Z"
                fill="#FC9732"
              />
              <path
                d="M53.9265 41.1465H60.2033C60.7617 41.1465 61.2972 41.3633 61.692 41.7493C62.0868 42.1353 62.3086 42.6588 62.3086 43.2046H51.8213C51.8213 42.6588 52.0431 42.1353 52.4379 41.7493C52.8327 41.3633 53.3682 41.1465 53.9265 41.1465Z"
                fill="#FC9732"
              />
              <path
                d="M57.0963 5.15594e-05C55.7428 -0.004029 54.4019 0.254295 53.1511 0.760082C51.9004 1.26587 50.7646 2.00908 49.8096 2.94672L51.037 4.15425C51.8312 3.37239 52.7758 2.75187 53.8164 2.32843C54.857 1.90499 55.973 1.68699 57.1002 1.68699C58.2273 1.68699 59.3433 1.90499 60.3839 2.32843C61.4245 2.75187 62.3691 3.37239 63.1634 4.15425L64.3908 2.94672C63.4343 2.00885 62.2972 1.26556 61.0451 0.759791C59.7931 0.254023 58.451 -0.00419588 57.0963 5.15594e-05Z"
                fill="#FC9732"
              />
              <path
                d="M52.2334 5.35473L53.4686 6.56226C53.9412 6.09956 54.5024 5.73248 55.1203 5.48203C55.7381 5.23158 56.4004 5.10267 57.0692 5.10267C57.738 5.10267 58.4003 5.23158 59.0182 5.48203C59.636 5.73248 60.1972 6.09956 60.6698 6.56226L61.8972 5.35473C60.6144 4.10443 58.8768 3.40234 57.0653 3.40234C55.2539 3.40234 53.5163 4.10443 52.2334 5.35473Z"
                fill="#FC9732"
              />
              <path
                d="M57.0652 9.14366C58.0348 9.14366 58.8209 8.37522 58.8209 7.4273C58.8209 6.47938 58.0348 5.71094 57.0652 5.71094C56.0956 5.71094 55.3096 6.47938 55.3096 7.4273C55.3096 8.37522 56.0956 9.14366 57.0652 9.14366Z"
                fill="#FC9732"
              />
            </svg>
          </Link>
          <h1>Vendor's registration</h1>
        </nav>
      </header>
      <main>
        <div>
          <div>
            <h2>Create Your Account</h2>
            <p>
              Already have an account? <Link to="/login/vendor">Log in</Link>
            </p>
          </div>
          <form onSubmit={formik.handleSubmit}>
         
              <div className="form-container">
                <div id="input-container">
                  <div className="full-length input-cont">
                    <label htmlFor="radio-station">Name of Radio Station</label>
                    <input
                      id="radio-station"
                      type="text"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      name="name"
                      placeholder="Beat FM"
                      required
                    />
                    {/* <span>{errors.name && touched.name && errors.name}</span> */}
                  </div>
                  <div className="full-length">
                    <div className="input-cont half-length">
                      <label htmlFor="login-username">Fullname</label>
                      <input
                        id="login-username"
                        type="text"
                        name="fullname"
                        value={formik.values.fullname}
                        onChange={formik.handleChange}
                        placeholder="John Doe"
                        required
                      />


                    </div>
                    <div className="input-cont half-length">
                      <label htmlFor="position-held">Position Held</label>
                      <input
                        id="position-held"
                        type="text"
                        value={formik.values.positionHeld}
                        onChange={formik.handleChange}
                        name="positionHeld"
              
                        placeholder="Marketer"
                        required
                      />

                    </div>
                  </div>
                  <div className="full-length input-cont">
                    <label htmlFor="address">Address</label>
                    <input
                      id="address"
                      type="text"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      name="address"
                      placeholder="2B, Montgomery Yaba, Lagos State."
                      required
                    />
                  </div>
                  <div className="full-length">
                    <div className="input-cont half-length">
                      <label htmlFor="country">Country</label>
                      <input
                        id="country"
                        type="text"
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        // name = "country"
                        // onChange={handleChange}

                        placeholder="Nigeria"

                        required
                      />
                      <button className="dropdown-icon">
                        <svg
                          width="11"
                          height="7"
                          viewBox="0 0 11 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.91911 0.953946L5.89413 5.25781L1.86914 0.953946"
                            stroke="#CACACA"
                            strokeWidth="1.7547"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="input-cont half-length">
                      <label htmlFor="state">State</label>
                      <input
                        id="state"
                        type="text"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        // name="state"
                        // onChange={handleChange}
                        placeholder="Lagos"
                        required
                      />
                      <button className="dropdown-icon">
                        <svg
                          width="11"
                          height="7"
                          viewBox="0 0 11 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.91911 0.953946L5.89413 5.25781L1.86914 0.953946"
                            stroke="#CACACA"
                            strokeWidth="1.7547"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="full-length">
                    <div className="input-cont half-length">
                      <label htmlFor="city">City</label>
                      <input id="city" type="text"
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        // onChange={handleChange}
                        placeholder="Lagos" required />
                    </div>
                    <div className="input-cont half-length">
                      <label htmlFor="zipcode">Zipcode</label>
                      <input
                        id="zipcode"
                        type="text"
                        name="zipcode"

                        value={formik.values.zipcode}
                        onChange={formik.handleChange}                    // onChange={handleChange}
                        placeholder="1001022"
                        required
                      />
                    </div>
                  </div>
                  <div className="full-length">
                    <div className="input-cont half-length">
                      <label htmlFor="station-website">Radio Station Website</label>
                      <input
                        id="station-website"
                        type="url"
                        name="radioStationWebsite"
                        value={formik.values.radioStationWebsite}
                        onChange={formik.handleChange}
                        // onChange={handleChange}
                        placeholder="Https://"
                        required
                      />
                    </div>
                    <div className="input-cont half-length">
                      <label htmlFor="station-category">
                        Radio Station Category
                      </label>
                      <input
                        id="station-category"
                        type="text"
                        name="radioStationCategory"
                        value={formik.values.radioStationCategory}
                        onChange={formik.handleChange}
                        // onChange={handleChange}
                        placeholder="Entertainment"
                        required
                      />
                      <button className="dropdown-icon">
                        <svg
                          width="11"
                          height="7"
                          viewBox="0 0 11 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.91911 0.953946L5.89413 5.25781L1.86914 0.953946"
                            stroke="#CACACA"
                            strokeWidth="1.7547"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="full-length input-cont">
                    <label htmlFor="stream-url">
                      Radio Station Digital Stream URL
                    </label>
                    <input
                      id="stream-url"
                      type="url"
                      name="radioStationDigitalStreaUrl"
                      value={formik.values.radioStationDigitalStreaUrl}
                      onChange={formik.handleChange}
                      // onChange={handleChange}
                      placeholder="Https://"
                      required
                    />
                  </div>
                  <div className="full-length">
                    <div className="input-cont half-length">
                      <label htmlFor="phone-no">Phone Number</label>
                      <input
                        id="phone-no"
                        type="text"
                        name="phoneNumber"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        // onChange={handleChange}
                        // {...register("phoneNumber", registerOption.phoneNumber
                        // )}
                        placeholder="+234"
                        required
                      />
                    </div>
                    <div className="input-cont half-length">
                      <label htmlFor="email">Email Address</label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        // onChange={handleChange}
                        placeholder="Enter your email address"
                        required
                      />
                    </div>
                  </div>
                  <div className="full-length">
                    <div className="input-cont half-length">
                      <label htmlFor="username">Username</label>
                      <input
                        id="username"
                        type="text"
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        placeholder="jane doe"
                        required
                      />
                    </div>
                    <div className="input-cont half-length">
                      <label htmlFor="login-password">Password</label>
                      <input
                        id="login-password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        // onChange={handleChange}
                        placeholder="Enter your Password"
                        required
                        // ref={loginPassword}
                      />
                      <button
                        type="button"
                        id="show-password"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <See />
                      </button>
                    </div>
                  </div>
                  <div className="full-length">
                    <div className="input-cont half-length">
                      <label htmlFor="login-password">confirmPassword</label>
                      <input
                        id="login-password"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        // onChange={handleChange}
                        placeholder="Enter your Password"
                        required
                        // ref={loginPassword}
                      />
                      <button
                        type="button"
                        id="show-password"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      
                      >
                        <See />
                      </button>
                    </div>
                  </div>
                </div>

                <div id="tac-pp">
                  <input
                    type="checkbox"
                    onClick={() => setConfirmAgreement(!confirmAgreement)}
                  />
                  {/* <button
                type="button"
                onClick={() => setDisable(true)} */}
                  {/* // onClick={() => {
                //   if (checkMark.current.style.display === "none") {
                //     checkMark.current.style.display = "block"; */}
                  {/* //   } else {
                //     checkMark.current.style.display = "none";
                //   }
                // }} */}
                  {/* // > */}
                  {/* <svg
                  ref={checkMark}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg> */}
                  {/* </button> */}
                  <p>
                    I acknowledge that I have read and accept the{" "}
                    <a href="gg">Terms and Conditions</a> and{" "}
                    <a href="fff">Privacy Policy</a>
                  </p>
                </div>
                <button id="login-button"
                  type="submit"
                  disabled={confirmAgreement}
                  className={confirmAgreement ? "disable-btn" : "enable-btn"}
                >Create Account</button>
              </div>
          </form>
        </div>
      </main>
    </section>
  );
};

export default SignupVendor;
