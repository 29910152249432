import React from "react";
import { Outlet } from "react-router-dom";

import NavBar from "../components/Navbar.js";

const BookCampaign = () => {
  return (
    <div>
      <NavBar
        bgClass="navbar-light"
        navTextColor="#004643"
        navFavColor="#FC9732"
      />
      <Outlet />
    </div>
  );
};

export default BookCampaign;
