import React, { useRef, useState } from "react";
import Calender from "./Calender";
import "./css/station-details.css";

// import TimeSchedule from "./TimeSchedule.js";


const StationDetails = ({ title, tagline, isOpen }) => {
  
  let dateObj = new Date();
  // eslint-disable-next-line
  const [minDate, setMinDate] = useState(
    `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toLocaleString(
      "en-US",
      {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }
    )}-${dateObj.getDate()}`
  );
  // eslint-disable-next-line
  const [maxDate, setMaxDate] = useState(
    `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toLocaleString(
      "en-US",
      {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }
    )}-${dateObj.getDate()}`
  );
  const [maxDateItem, setMaxDateItem] = useState([
    dateObj.getDay(),
    dateObj.getDate(),
    dateObj.getMonth(),
    dateObj.getFullYear(),
  ]);
  const [startDate, setStartDate] = useState(dateObj);
  const [endDate, setEndDate] = useState(dateObj);

  const startDatePicker = useRef();
  const startDateView = useRef();
  const endDatePicker = useRef();
  const endDateView = useRef();

  const getMonth = (param) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[param];
  };
  const getDay = (param) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days[param];
  };

  const setFormattedDate = (event, stat) => {
    let dateObject = new Date(event.target.value);
    let formattedDate = `${getDay(dateObject.getDay()).substring(
      0,
      3
    )}, ${dateObject.getDate()} ${getMonth(
      dateObject.getMonth()
    )}, ${dateObject.getFullYear()}`;
    // if the start calender is clicked
    if (stat === 0) {
      setStartDate(dateObject);
      startDateView.current.value = formattedDate;
      // set the "min" property of the "input#endDate" date picker
      endDatePicker.current.min = `${dateObject.getFullYear()}-${(
        dateObject.getMonth() + 1
      ).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}-${dateObject.getDate()}`;
      setMaxDateItem([
        dateObject.getDay(),
        dateObject.getDate(),
        dateObject.getMonth(),
        dateObject.getFullYear(),
      ]);
    }
    // if the end calender is clicked
    else if (stat === 1) {
      setEndDate(dateObject);
      endDateView.current.value = formattedDate;
      // set the "max" property of the "input#startDate" date picker
      startDatePicker.current.max = `${dateObject.getFullYear()}-${(
        dateObject.getMonth() + 1
      ).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}-${dateObject.getDate()}`;
    }
  };

  return (
    <div id="station-detail-cnt">
      <div id="station-title">
        <div>
          <h2>{title}</h2>
          <h3>{tagline}</h3>
        </div>
        <button
        onClick={isOpen}
        >View Time Schedule</button>
      </div>
      <div id="select-advert-details">
        <div>
          <label htmlFor="advert-type">Advert Type</label>
          <div>
            <select>
              <option>select advert type</option>
              <option>jingles</option>
              <option>paid advertisement</option>
            </select>
            {/* <input
              id="advert-type"
              type="text"
            //  readOnly 
              placeholder="Select Advert Type"
            />
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.5L6 6.5L11 1.5"
                stroke="#0E0E2C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg> */}
          </div>
          <p>Check Time Schedule for shows in your selected time slots.</p>
        </div>
        <div>
          <label htmlFor="duration">Duration</label>
          <div>
          <select>
              <option>30 secs</option>
              <option>60 secs</option>
              <option>90 secs</option>
            </select>
            {/* <input
              id="duration"
              type="text"
              readOnly
              placeholder="Select Seconds"
            />
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.5L6 6.5L11 1.5"
                stroke="#0E0E2C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg> */}
          </div>
        </div>
        <div>
          <label htmlFor="language">
            Select your preferred language for this campaign
          </label>
          <div>
          <select>
              <option>english</option>
              <option>yoruba</option>
              <option>pidgin</option>
            </select>
            {/* <input
              id="language"
              type="text"
              readOnly
              placeholder="Select Language"
            />
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.5L6 6.5L11 1.5"
                stroke="#0E0E2C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg> */}
          </div>
        </div>
      </div>
      <div id="select-advert-time">
        <h2>You have selected 14 Days</h2>
        <div id="select-date">
          <div>
            <label htmlFor="start-date">Start Date</label>
            <div>
              <input
                id="start-date"
                type="date"
                min={minDate}
                ref={startDatePicker}
                onChange={(e) => setFormattedDate(e, 0)}
              />
              <input
                ref={startDateView}
                className="formatted-date"
                type="text"
                placeholder={`${getDay(maxDateItem[0]).substring(0, 3)}, ${
                  maxDateItem[1]
                } ${getMonth(maxDateItem[2])}, ${maxDateItem[3]}`}
                readOnly
              />
            </div>
          </div>
          <div>
            <label htmlFor="end-date">End Date</label>
            <div>
              <input
                id="end-date"
                type="date"
                min={maxDate}
                ref={endDatePicker}
                onChange={(e) => setFormattedDate(e, 1)}
              />
              <input
                ref={endDateView}
                className="formatted-date"
                type="text"
                placeholder={`${getDay(maxDateItem[0]).substring(0, 3)}, ${
                  maxDateItem[1]
                } ${getMonth(maxDateItem[2])}, ${maxDateItem[3]}`}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
      <div id="set-time-slot">
        <h2>Click on dates to input spots.</h2>
        <Calender start={startDate} end={endDate} getDay={getDay} />
      </div>
      <dl id="booking-details">
        <h2>Booking Details</h2>
        <div>
          <dt>Radio Stations:</dt>
          <dd>{false | "3"}</dd>
        </div>
        <div>
          <dt>Total no of Days:</dt>
          <dd>{false | "2"}</dd>
        </div>
        <div>
          <dt>Total no of Spots:</dt>
          <dd>{false | "8"}</dd>
        </div>
        <div>
          <dt>Total Amount:</dt>
          <dd>NGN 517,455.00</dd>
        </div>
      </dl>
      <button id="checkout-btn">Proceed to Checkout</button>
    </div>
  );
};
export default StationDetails;
