import React, { useState, useEffect } from "react";
import "./css/time-schedule.css";

const TimeSchedule = () => {
  const data = [
    {
      Sunday: [
        { show: "Drivetime Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Morning Rush", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Midday Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Happy Sun Day", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Fun Wednesday", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
      ],
      Monday: [
        { show: "Drovetime Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Morning Rush", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Midday Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Happy Sun Day", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Fun Wednesday", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
      ],
      Tuesday: [
        { show: "Drivetime Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Morning Rush", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Midday Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Happy Sun Day", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Fun Wednesday", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
      ],
      Wednesday: [
        { show: "Drivetime Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Morning Rush", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Midday Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Happy Sun Day", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Fun Wednesday", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
      ],
      Thursday: [
        { show: "Drivetime Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Morning Rush", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Midday Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Happy Sun Day", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Fun Wednesday", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
      ],
      Friday: [
        { show: "Drivetime Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Morning Rush", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Midday Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Happy Sun Day", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Fun Wednesday", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
      ],
      Saturday: [
        { show: "Drivetime Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Morning Rush", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Midday Show", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Happy Sun Day", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
        { show: "Fun Wednesday", time: "4:00 PM - 9:00 PM", sponsor: "Mtn" },
      ],
    },
  ];

  let daySchedule;
  let changeSchedule = (dayArray) => {
    switch (dayArray) {
      case "Monday":
        console.log(data[0].Monday)
        daySchedule = data[0].Monday
        break;
      case "Tuesday":
        daySchedule = data[0].Tuesday
        break;
      case "Wednesday":
        daySchedule = data[0].Wednesday
        break;
      case "Thursday":
        daySchedule = data[0].Thursday
        break;
      case "Friday":
        daySchedule = data[0].Friday
        break;
      case "Saturday":
        daySchedule = data[0].Saturday
        break;
      case "Sunday":
        daySchedule = data[0].Sunday
        break;
      default:
        break;
    }

  };
  const [getDay, setGetDay] = useState(0)
  const [dayData, setDayData] = useState([])
  // const [isModal, setIsModal] = useState(false)
  const dayArray = Object.keys(data[0])
  console.log(dayArray)
  useEffect(() => {
    const getData = changeSchedule(dayArray[getDay])
    console.log(getData, 'getData')
    setDayData(daySchedule)
  }, [getDay])


  // eslint-disable-next-line 
  // const [timeScheduleData, setTimeScheduleData] = useState(data);
  // const [currentDay, setCurrentDay] = useState("Sunday");
  // const [allDayBtn, setAllDayBtn] = useState([]);
  // const [prevDayBtn, setPrevDayBtn] = useState(0);
  // const timeScheduleTable = useRef();
  // const dayBtnCont = useRef();

  // useEffect(() => {
  //   timeScheduleTable.current.innerHTML = "";
  //   timeScheduleData[0][currentDay].forEach((schedule) => {
  //     timeScheduleTable.current.innerHTML += `<div>
  //           <div>
  //               ${schedule.show}
  //           </div>
  //           <div>
  //               ${schedule.time} 
  //           </div>
  //           <div>
  //               ${schedule.sponsor}
  //           </div>
  //       </div>`;
  //   });
  // }, [timeScheduleData, currentDay]);
  // useEffect(() => {
  //   setAllDayBtn([dayBtnCont.current.querySelectorAll("button")]);
  // }, [dayBtnCont]);

  // let changeSchedule = (id, day, element) => {
  //   allDayBtn[0][prevDayBtn].classList.remove("active-time");
  //   setCurrentDay(day);
  //   element.target.classList.add("active-time");
  //   setPrevDayBtn(id);
  // };

  return (
    <div id="time-schedule">
      <div>
        <div id="time-btn">
          {dayArray.map((day, index) => {
            return <button
              onClick={() => setGetDay(index)}
              className={index === getDay ? "bg-color" : ""}
            >{day}</button>
          })}
        </div>
        <div className="table-body-container view-schedule">
          <table className="view-schedule-table">
            <thead>
              <tr>
                <th>show</th>
                <th>time</th>
                <th>schedule</th>
              </tr>

            </thead>
            <tbody>
              {dayData.map((data) => {
                return <tr>
                  <td>{data.show}</td>
                  <td>{data.time}</td>
                  <td>{data.sponsor}</td>
                </tr>
              })}

            </tbody>
          </table>
        </div>
        {/* <div id="day-btn-cont">
        <div ref={dayBtnCont}>
          <button
            className="active-time"
            onClick={(e) => {
              changeSchedule(0, "Sunday", e);
            }}
          >
            Sunday
          </button>
          <button
            onClick={(e) => {
              changeSchedule(1, "Monday", e);
            }}
          >
            Monday
          </button>
          <button
            onClick={(e) => {
              changeSchedule(2, "Tuesday", e);
            }}
          >
            Tuesday
          </button>
          <button
            onClick={(e) => {
              changeSchedule(3, "Wednesday", e);
            }}
          >
            Wednesday
          </button>
          <button
            onClick={(e) => {
              changeSchedule(4, "Thursday", e);
            }}
          >
            Thursday
          </button>
          <button
            onClick={(e) => {
              changeSchedule(5, "Friday", e);
            }}
          >
            Friday
          </button>
          <button
            onClick={(e) => {
              changeSchedule(6, "Saturday", e);
            }}
          >
            Saturday
          </button>
        </div>
      </div>
      <div id="time-schedule-table">
        <div>
          <div>Show</div>
          <div>Time</div>
          <div>Sponsor</div>
        </div>
        <div ref={timeScheduleTable}></div>
      </div> */}
      </div>
    </div>
  );
};

export default TimeSchedule;
