import React, { useState, useRef } from "react";
import "./css/budget-filter.css";

const BudgetFilter = () => {
  const [min, setMin] = useState(5000);
  const [max, setMax] = useState(15000);
  const slideWidth = useRef();

  const setPriceFilter = () => {
    let slideLength = 30000;
    slideWidth.current.style.width = `${((max - min) / slideLength) * 100}%`;
    slideWidth.current.style.margin = `auto ${
      ((slideLength - max) / slideLength) * 100
    }% auto ${(min / slideLength) * 100}%`;
  };

  return (
    <div id="budget-filter">
      <div>
        <h2>Budget Filter</h2>
        <button>Reset Filter</button>
      </div>
      <div>
        <div>
          <h2>Select your budget per radio station.</h2>
          <h3>
            {min} - {max}
          </h3>
          <div id="range-slider">
            <div className="slider-track"></div>
            <div className="slide-width" ref={slideWidth}></div>
            <input
              type="range"
              min="0"
              max="30000"
              value={min}
              id="slider-1"
              onChange={({ target: { value: radius } }) => {
                if (parseInt(radius) > max) {
                  setMin(parseInt(radius));
                  setMax(parseInt(radius));
                } else {
                  setMin(parseInt(radius));
                }
                setPriceFilter();
              }}
            />
            <input
              type="range"
              min="0"
              max="30000"
              value={max}
              id="slider-2"
              onChange={({ target: { value: radius } }) => {
                if (parseInt(radius) < min) {
                  setMin(parseInt(radius));
                  setMax(parseInt(radius));
                } else {
                  setMax(parseInt(radius));
                }
                setPriceFilter();
              }}
            />
          </div>
          <button>Apply</button>
        </div>
        <form>
          <div>
            <input
              type="text"
              id="filter-location"
              placeholder="Sort by Location"
              readOnly
            />
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.97583 1.44727L6.33535 6.80678L11.6949 1.44727"
                stroke="#004643"
                strokeWidth="1.33988"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div>
            <input
              type="text"
              id="filter-location"
              placeholder="Sort by Type"
              readOnly
            />
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.97583 1.44727L6.33535 6.80678L11.6949 1.44727"
                stroke="#004643"
                strokeWidth="1.33988"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </form>
        <div>
          <h3>Stay up-to-date with latest news and changes in our rate.</h3>
          <form>
            <input type="email" placeholder="Enter Your Email" />
            <button type="submit">Sign Up</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BudgetFilter;
