import React from 'react'
import { Link } from 'react-router-dom';
import AdvertDashboardLayout from '../../ResponsiveAdvertiserDashboardLayout/AdvertDashboardLayout';
import "../css/draft.css"
import FilterLayout from './filter-layout';

const Draft = () => {
    const data = [
        {
            date: "Mar 28th 2022",
            vendors: "Classic FM 97.3",
            noOfVendors: "2",
            type: "jingles",
            language: "Yoruba",
            duration: "60Sec",
            slots: "16",
            subTotal: "₦2,741.25"
        },
        {
            date: "Mar 28th 2022",
            vendors: "Classic FM 97.3",
            noOfVendors: "2",
            type: "jingles",
            language: "Yoruba",
            duration: "60Sec",
            slots: "16",
            subTotal: "₦2,741.25"
        },
        {
            date: "Mar 28th 2022",
            vendors: "Classic FM 97.3",
            noOfVendors: "2",
            type: "jingles",
            language: "Yoruba",
            duration: "60Sec",
            slots: "16",
            subTotal: "₦2,741.25"
        },
        {
            date: "Mar 28th 2022",
            vendors: "Classic FM 97.3",
            noOfVendors: "2",
            type: "jingles",
            language: "Yoruba",
            duration: "60Sec",
            slots: "16",
            subTotal: "₦2,741.25"
        },
        {
            date: "Mar 28th 2022",
            vendors: "Classic FM 97.3",
            noOfVendors: "2",
            type: "jingles",
            language: "Yoruba",
            duration: "60Sec",
            slots: "16",
            subTotal: "₦2,741.25"
        },
        {
            date: "Mar 28th 2022",
            vendors: "Classic FM 97.3",
            noOfVendors: "2",
            type: "jingles",
            language: "Yoruba",
            duration: "60Sec",
            slots: "16",
            subTotal: "₦2,741.25"
        },
    ]
  return (
    <AdvertDashboardLayout>
        <div className='draft-dashboard-container'>
<FilterLayout />
<div  className='station-table-container'>
<table>
<thead>
                <tr>
                <th>
                <input name="select_all" value="1" type="checkbox" />
                </th>
                <th>date</th>
                <th>vendors</th>
                <th>type</th>
                <th>language</th>
                <th>duration</th>
                <th>slots</th>
                <th
                colSpan={2}
                >subtotals</th>
                </tr>
                
            </thead>
            <tbody>
                {data.map((tableInfo) => {
                    return <tr>
                        <td>
                        <input name="select_all" value="1" type="checkbox" />
                        </td>
                        <td>{tableInfo.date}</td>
                        <td>{tableInfo.vendors}</td>
                        <td>{tableInfo.type}</td>
                        <td>{tableInfo.language}</td>
                        <td>{tableInfo.duration}</td>
                        <td>{tableInfo.slots}</td>
                        <td>{tableInfo.subTotal}
                        </td>
                        <td>
                            <Link>proceed to checkout</Link>
                        </td>
                    </tr>
                })}
            </tbody>
</table>
</div>
        </div>
    </AdvertDashboardLayout>
  )
}

export default Draft;