import React from 'react';
import './css/talk-with-an-expert.css';

import NavBar from '../components/Navbar.js';

import FormSuccessPopUp from '../assets/images/success-popup.gif';

const TalkWithAnExpert = ()=> {
    return (
      <div id="talk-with-expert">
        <NavBar
          bgClass="navbar-dark"
          navTextColor="#FFFFFE"
          navFavColor="#FC9732"
        />
        <main>
          <section>
            <div>
              <h1>Consult an Expert</h1>
              <div>
                <h2>AN EXPERT WILL GET IN TOUCH WITH YOU</h2>
                <form>
                  <div>
                    <label htmlFor="name">Name:</label>
                    <input
                      id="name"
                      type="text"
                      placeholder="Enter your name"
                      required
                    />
                    <span className="error-icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#FB2730"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 8V12"
                          stroke="#FB2730"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 16H12.01"
                          stroke="#FB2730"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <div></div>
                  </div>
                  <div>
                    <label htmlFor="email">Email Address:</label>
                    <input
                      id="email"
                      type="text"
                      placeholder="Enter your email address"
                      required
                    />
                    <span className="error-icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#FB2730"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 8V12"
                          stroke="#FB2730"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 16H12.01"
                          stroke="#FB2730"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <div></div>
                  </div>
                  <div>
                    <label htmlFor="phone-no">Phone Number:</label>
                    <input
                      id="phone-no"
                      type="text"
                      placeholder="Enter your phone number"
                      required
                    />
                    <span className="error-icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#FB2730"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 8V12"
                          stroke="#FB2730"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 16H12.01"
                          stroke="#FB2730"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <div></div>
                  </div>
                  <div>
                    <label htmlFor="advert-type">Advert Type:</label>
                    <input
                      id="advert-type"
                      type="text"
                      placeholder="choose your advert type"
                      required
                    />
                    <svg
                      width="8"
                      height="5"
                      viewBox="0 0 8 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.856445 0.705078L3.99314 3.84177L7.12983 0.705078"
                        stroke="#0E0E2C"
                        strokeWidth="0.941007"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="error-icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#FB2730"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 8V12"
                          stroke="#FB2730"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 16H12.01"
                          stroke="#FB2730"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <div></div>
                  </div>
                  <div>
                    <label htmlFor="brief">Project Brief:</label>
                    <textarea
                      id="brief"
                      placeholder="Describe your project"
                      required
                    ></textarea>
                    <span className="error-icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#FB2730"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 8V12"
                          stroke="#FB2730"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 16H12.01"
                          stroke="#FB2730"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <div></div>
                  </div>
                  <button>Submit</button>
                </form>
              </div>
            </div>
          </section>
        </main>
        <aside>
          <div>
            <img src={FormSuccessPopUp} alt="" />
            <h2>Success! Your project brief has been sent!</h2>
            <h3>An expert will get in touch with you shortly!</h3>
            <button>Back to Dashboard</button>
          </div>
        </aside>
      </div>
    );
}

export default TalkWithAnExpert;