import React from 'react'
import AdvertDashboardLayout from '../../ResponsiveAdvertiserDashboardLayout/AdvertDashboardLayout'
import CardTwo from "../../assets/paymentCardTwo.svg"
import CardThree from "../../assets/paymentCardThree.svg"
import "../css/wallet.css"
import FilterLayout from './filter-layout'

const Wallet = () => {
    const data = [
        {
            transactionDate: "Mar 28th 2022",
            transactionId: "324534",
            amount: "₦2,741.25",
            type: "₦2,741.25",
            paymentMethod: "Flutterwave",
            description: "account top up"
        },
        {
            transactionDate: "Mar 28th 2022",
            transactionId: "324534",
            amount: "₦2,741.25",
            type: "₦2,741.25",
            paymentMethod: "Flutterwave",
            description: "account top up"
        },
        {
            transactionDate: "Mar 28th 2022",
            transactionId: "324534",
            amount: "₦2,741.25",
            type: "₦2,741.25",
            paymentMethod: "Flutterwave",
            description: "account top up"
        },
        {
            transactionDate: "Mar 28th 2022",
            transactionId: "324534",
            amount: "₦2,741.25",
            type: "₦2,741.25",
            paymentMethod: "Flutterwave",
            description: "account top up"
        },
    ]
    return (
        <AdvertDashboardLayout>
            <div className='wallet-container'>
                <div>
                    <div className='wallet-header'>
                        <h4>wallet</h4>
                        <div>
                            <div>current balance: <span>₦44, 902:00</span></div>
                            <div>pending amount:  <span>₦4,000.000</span></div>
                        </div>
                    </div>
                    <div className='campaign-form-card-wrapper'>
                        {/* <h4>Select your preffered payment method</h4> */}
                        <div className='payment-card-wrapper'>

                            <div>
                                <div>
                                    <img src={CardTwo} alt='paystack'/>

                                </div>
                                <span> Pay with Paystack</span>

                            </div>
                            <div>
                                <div>
                                    <img src={CardThree} alt='flutter-card'/>

                                </div>
                                <span>Pay with Flutterwave</span>

                            </div>
                        </div>
                        <div className='amount_input_wrap'>
                            <label htmlFor="campaign_name">Amount:</label>
                            <input type="text" placeholder="Enter an amount" />
                        </div>
                        <button>proceed</button>

                    </div>
                </div>
                <FilterLayout />
                <div className='station-table-container'>
                    <table>
                    <thead>
                            <tr>
                                <th><input name="select_all" value="1" type="checkbox" /></th>
                                <th>Transaction Date</th>
                                <th>Transaction ID</th>
                                <th>Amount</th>
                                <th>type</th>
                                <th>paymentMethod</th>
                                <th>Description</th>
                                
                            </tr>
                        </thead>
                        <tbody>

{data.map((info) => {
    return (
        <tr>
            <td><input type="checkbox" name="name1" /></td>
            <td>{info.transactionDate}</td>
            <td>{info.transactionId}</td>
            <td>{info.amount}</td>
            <td>{info.type}</td>
            <td>{info.paymentMethod}</td>
            <td>
                {info.description}
            </td>
          
        </tr>
    )
})}
</tbody>
                    </table>
                </div>
            </div>
        </AdvertDashboardLayout>
    )
}

export default Wallet