import { gql } from "@apollo/client"

export const ADVERTISER_REGISTER = gql`
mutation Auth(
    $state:String!
$phoneNumber:String!
$password:String!
$industry:String!
$fullname:String!
$email:String!
$country:String!
$confirmPassword:String!
$city:String!
$address:String!
$accountType:AccountType!

) {
  auth {
    advertiserRegister(
        input: {
            state: $state
phoneNumber: $phoneNumber
password: $password
industry: $industry
fullname: $fullname
email: $email
country: $country
confirmPassword: $confirmPassword
city: $city
address: $address
accountType: $accountType
        }
    ) {
        token
    }
  }
}`

export const ADVERTISER_LOGIN = gql`

mutation Login(
  $email: String!
  $password: String!
) {
  auth {
    login(
      input: {
        email: $email,
        password: $password
      }
    ) {
      token
    }
  }
}

`

export const BROADCASTER_REGISTER = gql`
mutation BroadcasterRegister(
    $zipcode: String!
    $state: String!
    $radioStationWebsite: String!
    $radioStationDigitalStreaUrl: String!
    $radioStationCategory: String!
    $positionHeld: String!
    $phoneNumber: String!
    $password: String!
    $name: String!
    $fullname: String!
    $email: String!
    $country: String!
    $confirmPassword: String!
    $city: String!
    $address: String!
) {
  auth {
    broadcasterRegister(
      input: {
            zipcode: $zipcode
    state: $state
    radioStationWebsite: $radioStationWebsite
   radioStationDigitalStreaUrl: $radioStationDigitalStreaUrl
    radioStationCategory: $radioStationCategory
    positionHeld: $positionHeld
    phoneNumber: $phoneNumber
    password: $password
    name: $name
    fullname: $fullname
    email: $email
    country: $country
    confirmPassword: $confirmPassword
    city: $city
    address: $address
    }) {
      token
    }
  }
}
`
export const CREATE_CAMPAIGN = gql`
mutation CreateCampaign(
  $name: String!,
      $links: String!,
      $companyName: String!,
      $industryName: String!,
      $files: Upload!,
) {
  advertiser {
    createCampaign(
      input: {
      name: $name,
      links: $links,
      companyName: $companyName
      industryName: $industryName
      files: $files
    }) {
      name,
      links,
      companyName,
      industryName
      filename                           
    }
  }
}
`