import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
// import * as Yup from "yup"
import { ADVERTISER_REGISTER } from '../../components/GraphQL/Mutation'
import { useMutation } from '@apollo/client'
import { ReactComponent as See } from "../../assets/icons/eye.svg"
import { AUTH_TOKEN } from '../../constant'


import "../css/signup-advertiser.css"

const AdvertiserSignup = () => {

    const navigate = useNavigate()

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmAgreement, setConfirmAgreement] = useState(true)
    const [signup] = useMutation(ADVERTISER_REGISTER)

    const formik = useFormik({
        initialValues: {
            fullname: "",
            industry: "",
            address: "",
            companyName: "",
            country: "",
            state: "",
            email: "",
            phoneNumber: "",
            username: "",
            zipcode: "",
            password: "",
            confirmPassword: "",
            accountType: "Individual"
        },

        onSubmit: (values) => {
            signup({
                variables: {
                    fullname: values.fullname,
                    industry: values.industry,
                    address: values.address,
                    companyName: values.companyName,
                    country: values.country,
                    state: values.state,
                    city: values.city,
                    email: values.email,
                    phoneNumber: values.phoneNumber,
                    username: values.username,
                    zipcode: values.zipcode,
                    password: values.password,
                    confirmPassword: values.confirmPassword,
                    accountType: values.accountType
                },
                onCompleted: (data) => {
                    localStorage.setItem(AUTH_TOKEN, data.auth.advertiserRegister.token);
                    setTimeout(navigate("/dashboard"), 10000);
                }
            })
        }
    })
    console.log(formik.values, 'hey you')
    return (
        <section id="signup-vendor">
            <header>
                <nav>
                    <Link to="/"></Link>
                    <h1>Advertiser's registration</h1>
                </nav>
            </header>
            <main>
                <div>
                    <div>
                        <h2>Create Your Account</h2>
                        <p>
                            Already have an account? <Link to="/login">Log in</Link>
                        </p>
                    </div>
                    <h4>Please select your prefered account type</h4>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className='form-container'>
                        <div id='input-container'>
                            <div className='advertiser-type-button'>
                                <button
                                    type='button'
                                    onClick={() => {
           
                                        if (formik.values.accountType === "company") {
              
                                          formik.setFieldValue("accountType", "Individual")
              
                                        }
              
                                      }
                                    }
                                    className={formik.values.accountType === "Individual" ? "active-btn" : "not-active"}
                                >
                                    Individual
                                </button>
                                <button
                                    type='button'
                                // onClick={() => {
                                //     if(values.accountType === 'company') {

                                //     }
                                // }}
                                onClick={() => {
           
                                    if (formik.values.accountType === "Individual") {
          
                                      formik.setFieldValue("accountType", "company")
          
                                    }
          
                                  }
                                }
                                className={formik.values.accountType === "company" ? "active-btn" : "not-active"}
                                >
                                    company
                                </button>
                            </div>
 {          formik.values.accountType === "company" &&                 <div className='full-length input-cont'>
                                <label htmlFor="radio-station">Company name</label>
                                <input
                                    // id="radio-station"
                                    type="text"
                                    placeholder="Space Universe"
                                    name="companyName"
                                    value={formik.values.companyName}
                                    onChange={formik.handleChange}
                                    // onBlur={handleBlur}
                                    required
                                />
                            </div>}
                            <div className='full-length input-cont'>
                                <label htmlFor="radio-station">Full name</label>
                                <input
                                    // id="radio-station"
                                    type="text"
                                    placeholder="John Doe"
                                    name="fullname"
                                    value={formik.values.fullname}
                                    onChange={formik.handleChange}
                                    // onBlur={handleBlur}
                                    required
                                />
                            </div>
                            <div className="full-length input-cont">
                                <label htmlFor="radio-station">Industry</label>
                                <input
                                    // id="radio-station"
                                    type="text"
                                    placeholder="Beat FM"
                                    name="industry"
                                    value={formik.values.industry}
                                    onChange={formik.handleChange}
                                    //   onBlur={handleBlur}
                                    required
                                />
                            </div>
                            <div className="full-length input-cont">
                                <label htmlFor="radio-station">Address </label>
                                <input
                                    // id="radio-station"
                                    type="text"
                                    placeholder="2B, Montgomery Yaba, Lagos State."
                                    name="address"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    //   onBlur={handleBlur}
                                    required
                                />
                            </div>
                            <div className='full-length'>
                                <div className='input-cont half-length'>
                                    <label htmlFor="city">Country</label>
                                    <input id="country" type="text" placeholder="Nigeria"
                                        name="country"
                                        value={formik.values.country}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                </div>
                                <div className="input-cont half-length">
                                    <label htmlFor="zipcode">State</label>
                                    <input
                                        id="state"
                                        type="text"
                                        placeholder="Lagos"
                                        name="state"
                                        value={formik.values.state}
                                        onChange={formik.handleChange}
                                        required
                                    />

                                </div>
                            </div>
                            <div className='full-length'>
                                <div className='input-cont half-length'>
                                    <label htmlFor="city">City</label>
                                    <input id="city" type="text" placeholder="Lagos"
                                        name="city"
                                        value={formik.values.city}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                </div>
                                <div className="input-cont half-length">
                                    <label htmlFor="zipcode">Zipcode</label>
                                    <input
                                        id="zipcode"
                                        type="text"
                                        placeholder="1001022"
                                        name="zipcode"
                                        value={formik.values.zipcode}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='full-length'>
                                <div className='input-cont half-length'>
                                    <label htmlFor="phone-no">Phone Number</label>
                                    <input
                                        id="phone-no"
                                        type="text"
                                        placeholder="+234"
                                        name="phoneNumber"
                                        value={formik.values.phoneNumber}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                </div>
                                <div className="input-cont half-length">
                                    <label htmlFor="email">Email Address</label>
                                    <input
                                        id="email"
                                        type="email"
                                        placeholder="Enter your email address"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='full-length'>
                                <div className='input-cont half-length'>
                                    <label htmlFor="username">Username</label>
                                    <input
                                        id="username"
                                        type="text"
                                        placeholder="jane doe"
                                        name="username"
                                        value={formik.values.username}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                </div>
                                <div className='input-cont half-length'>
                                    <label htmlFor="login-password">Password</label>
                                    <input
                                        // id="login-password"
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Enter your Password"
                                        name="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        required
                                    // ref={signupPassword}
                                    />
                                    <button
                                        type="button"
                                        id="show-password"
                                        onClick={() => setShowPassword(!showPassword)}
                                    //   onClick={() => {
                                    //     if (signupPassword.current.type === "text") {
                                    //       signupPassword.current.type = "password";
                                    //     } else {
                                    //       signupPassword.current.type = "text";
                                    //     }
                                    //   }}
                                    >
                                        <See />
                                    </button>
                                </div>
                            </div>
                            <div className='full-length'>
                                <div className='input-cont half-length'>
                                    <label htmlFor="login-password">confirmPassword</label>
                                    <input
                                        // id="login-password"
                                        type={showConfirmPassword ? "text" : "password"}
                                        placeholder="Enter your Password"
                                        name="confirmPassword"
                                        value={formik.values.confirmPassword}
                                        onChange={formik.handleChange}
                                        // onBlur={handleBlur}
                                        required
                                    // ref={signupPassword}
                                    />
                                    <button
                                        type="button"
                                        id="show-password"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    // onClick={() => {
                                    //   if (signupPassword.current.type === "text") {
                                    //     signupPassword.current.type = "password";
                                    //   } else {
                                    //     signupPassword.current.type = "text";
                                    //   }
                                    // }}
                                    >
                                        <See />
                                    </button>
                                </div>
                            </div>
                            <div id='tac-pp'>
                                <input
                                    type="checkbox"
                                    onClick={() => setConfirmAgreement(!confirmAgreement)}
                                //   onClick={() => setDisable(!disable)}
                                />
                                <p>
                                    I acknowledge that I have read and accept the{" "}
                                    <a href="gg">Terms and Conditions</a> and{" "}
                                    <a href="fff">Privacy Policy</a>
                                </p>
                            </div>
                        </div>
                        <button
                            id="login-button"
                            type="submit"
                            disabled={confirmAgreement}
                            className={confirmAgreement ? "disable-btn" : "enable-btn"}
                        >Create Account</button>
                    </div>
                </form>
            </main>
        </section >
    )
}

export default AdvertiserSignup