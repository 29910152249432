import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/main-footer-sect.css";
import Logo from "./Logo";
import { ReactComponent as FootDdIcon } from "../assets/icons/footer/footer-dd.svg";
import { ReactComponent as FootPhoneIcon } from "../assets/icons/footer/phone.svg";
import { ReactComponent as FootLocationIcon } from "../assets/icons/footer/location.svg";
import { ReactComponent as FootInstagramIcon } from "../assets/icons/footer/instagram.svg";
import { ReactComponent as FootTwitterIcon } from "../assets/icons/footer/twitter.svg";
import { ReactComponent as FootLinkedInIcon } from "../assets/icons/footer/linkedIn.svg";
import { ReactComponent as FootFacebookIcon } from "../assets/icons/footer/facebook.svg";

const MainFooterSect = () => {
  const [ddOneShow, setddOneShow] = useState(true);
  const [ddTwoShow, setddTwoShow] = useState(true);
  const [ddThreeShow, setddThreeShow] = useState(true);
  const toggleNavItem = (ctrlVar, ctrlSetter) => {
    ctrlSetter(!ctrlVar);
  };
  return (
    <section id="main-footer-sect">
      <div>
        <div>
          <div>
            <Link to="/">
              <Logo textColor={"#FFFFFE"} favColor={"#FC9732"} />
            </Link>
            <p>
              RadioAdSpread is an AI-driven global advertisement platform that
              digitalizes the operation of radio advert globally while providing
              real-time report of the campaign.
            </p>
          </div>
          <address>
            <div>
              <span>
                <FootPhoneIcon />
              </span>
              <span>(+234) 81 7710 0095</span>
            </div>
            <div>
              <span>
                <FootLocationIcon />
              </span>
              <span>2b Montgomery Rd, Yaba, Lagos, Nigeria</span>
            </div>
          </address>
        </div>
        <dl>
          <dt>
            <span>GO</span>
            <button
              className={ddOneShow ? "foot-btn-spin" : null}
              onClick={() => {
                toggleNavItem(ddOneShow, setddOneShow);
              }}
            >
              <FootDdIcon />
            </button>
          </dt>
          <div className={ddOneShow ? "foot-dd-open" : null}>
            <dd>Contact Us</dd>
            <dd>Help Center</dd>
            <dd>Knowledge Base</dd>
          </div>
        </dl>
        <dl>
          <dt>
            <span>COMPANY</span>
            <button
              className={ddTwoShow ? "foot-btn-spin" : null}
              onClick={() => {
                toggleNavItem(ddTwoShow, setddTwoShow);
              }}
            >
              <FootDdIcon />
            </button>
          </dt>
          <div className={ddTwoShow ? "foot-dd-open" : null}>
            <dd>About us</dd>
            <dd>Support</dd>
            <dd>Terms and condition</dd>
            <dd>Cookie Policy</dd>
            <dd>Privacy Policy</dd>
          </div>
        </dl>
        <dl>
          <dt>
            <span>SOLUTION</span>
            <button
              className={ddThreeShow ? "foot-btn-spin" : null}
              onClick={() => {
                toggleNavItem(ddThreeShow, setddThreeShow);
              }}
            >
              <FootDdIcon />
            </button>
          </dt>
          <div className={ddThreeShow ? "foot-dd-open" : null}>
            <dd>Advertiser</dd>
            <dd>Broadcaster</dd>
            <dd>Pricing</dd>
          </div>
        </dl>
        <div id="foot-socials">
          <span>CONNECT</span>
          <div>
            <FootFacebookIcon />
            <FootLinkedInIcon />
            <FootTwitterIcon />
            <FootInstagramIcon />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainFooterSect;
