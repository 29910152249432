import React from 'react'
import "../style.css"

const ScheduleModal = () => {
return (
    <div style={{position: "fixed", height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center",
    zIndex: 999, background: "#EFECEC"

    }}>
         <div className='schedule-container'>
            <div className='cancel-wrapper'>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2989_3748)">
<path d="M11.25 3.75L3.75 11.25" stroke="#0E0E2C" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.75 3.75L11.25 11.25" stroke="#0E0E2C" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_2989_3748">
<rect width="15" height="15" fill="white"/>
</clipPath>
</defs>
</svg>

            </div>

    <section className='schedule-input'>
<div>
    <label>Show Title</label>
    <input type='text' placeholder='Enter the show title'/>
</div>
<div>
    <label>Sponsor:</label>
    <input type='text' placeholder='Enter sponsors name'/>
</div>
    </section>
    <section className='schedule-time-wrapper'>
        <div className='schedule-time-dropdown'>
        
        <label>from</label>
        <div>

            <select>
                <option>10</option>
                <option>10</option>
                <option>10</option>
            </select>
            :
            <select>
                <option>10</option>
                <option>10</option>
                <option>10</option>
            </select>
            :
            <select>
                <option>am</option>
                <option>pm</option>
            </select>
        </div>
        
       
        </div>
        <div className='schedule-time-dropdown'>
        
        <label>to</label>
        <div>
            <select>
                <option>10</option>
                <option>10</option>
                <option>10</option>
            </select>
            :
            <select>
                <option>10</option>
                <option>10</option>
                <option>10</option>
            </select>
            :
            <select>
                <option>am</option>
                <option>pm</option>
            </select>
        </div>
        </div>
    </section>
    <div className='add-btn'>
            <button>Add New Schedule</button>
          </div>
    </div>
        </div>
   
)
}

export default ScheduleModal;


// const days = [
//     "Sunday",
//     "Monday",
//     "Tuesday",
//     "Wednesday",
//     "Thursday",
//     "Friday",
//     "Saturday"
// ]

// const [dayIndex, setDayIndex] = useState(0)
// const timeTable = [
//     {
//         showTitle: "DrivetimeShow",
//         time: "4:00 PM - 9:00 PM",
//         sponsor: "MTN"
//     },
//     {
//         showTitle: "DrivetimeShow",
//         time: "4:00 PM - 9:00 PM",
//         sponsor: "MTN"
//     },
//     {
//         showTitle: "DrivetimeShow",
//         time: "4:00 PM - 9:00 PM",
//         sponsor: "MTN"
//     },
//     {
//         showTitle: "DrivetimeShow",
//         time: "4:00 PM - 9:00 PM",
//         sponsor: "MTN"
//     },
//     {
//         showTitle: "DrivetimeShow",
//         time: "4:00 PM - 9:00 PM",
//         sponsor: "MTN"
//     },
// ]
// return (
    // <div className='overlay-modal'>
        // <div className='modal-wrapper'>
        // <div style={{display: "flex", flexDirection: "row", width: "100%", gap: "10px"}}>
        //    {days.map((day, index) => {
        //         return (

        //             <button className= "day-btn" style={{ padding: "5px", border: "1px solid red" }}
        //                 onClick={() => setDayIndex(index)}
        //             >{day}</button>
                    
        //         )
        //     })}
        //     </div>
            // </div>
    // </div>
    // <div className="over-modal">
    //     <div className="moda-wrapper">

            /* <div>
                <table className='table'>
                    <tr>
                        <th>Show</th>
                        <th>Time</th>
                        <th>Sponsor</th>
                    </tr>                    
                        {timeTable.map((show) => {
                            return (
                                <tr>
                                    <td>{show.showTitle}</td>
                                    <td>{show.time}</td>
                                    <td>{show.sponsor}</td>
                                    </tr>
                            )
                        })}
                </table>
            </div> */
    //     </div>
    // </div>
// )