export const ProtectedRoute = ({children}) => {
    if (!localStorage.getItem('token')) {

        window.location = '/'
    } else {
        return children;

    }
}

export default ProtectedRoute