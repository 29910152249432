import React, {useState} from "react";
import "./css/page-three.css";

import SearchRadioStation from "../components/SearchRadioStation.js";
import MainFooterSect from "../../components/MainFooterSect.js";
import CardOne from "../../pages/ResponsiveAdvertiserDashboard/assets/paymentCard.svg"
import CardTwo from "../../pages/ResponsiveAdvertiserDashboard/assets/paymentCardTwo.svg"
import CardThree from "../../pages/ResponsiveAdvertiserDashboard/assets/paymentCardThree.svg"
import FilePlus from "../../pages/ResponsiveAdvertiserDashboard/assets/file-plus.svg"
import BeatFm from "../../pages/ResponsiveAdvertiserDashboard/assets/beatfm.svg"
import ClassicFm from "../../pages/ResponsiveAdvertiserDashboard/assets/classicfm.svg"

const PageThree = () => {
  const [isProceed, setIsProceed] = useState(false)
  return (
    <div>
      <main>
        <SearchRadioStation />
        <div className='checkout-container checkout'>
                <div className='checkout-header'>
                    <h4>campaign details</h4>
                    <div>
                        <span>wallet balance: </span>
                        <span>NGN 1,000</span>
                    </div>
                </div>
                <div className='checkout-wrapper'>
                <form>
                    <h4 className='card-option-wrapper'>Select your preffered payment method</h4>
                    <div className='payment-card-wrapper'>
                        <div>
                            <div>
                                <img src={CardOne} 
                                alt='wallet-icon'
                                />

                            </div>
                            <span className='card-type-wrapper'>
                                PAY FROM WALLET
                            </span>
                        </div>
                        <div>
                            <div>
                                <img src={CardTwo} 
                                alt='paystack-icon'
                                />

                            </div>
                            <span
                                className='card-type-wrapper'
                            >
                                PAY WITH PAYSTACK

                            </span>
                        </div>
                        <div>
                            <div>
                                <img src={CardThree} 
                                alt='flutter-icon'
                                />

                            </div>
                            <span
                                className='card-type-wrapper'
                            >
                                PAY WITH FLUTTERWAVE
                            </span>

                        </div>
                    </div>
                    <div className='input_wrap'>
                        <label htmlFor="campaign_name">Campaign Name:</label>
                        <input type="text" placeholder="Enter campaign name" className="input_field" />
                    </div>
                    <div className='input_wrap'>
                        <label htmlFor="company_name">Company Name:</label>
                        <input type="text" placeholder="Enter your ompany name" className="input_field" />
                    </div>
                    <div className='input_wrap'>
                        <label htmlFor="industry_name">Name of Industry:</label>
                        <input type="text" placeholder="Enter industry name" className="input_field" />
                    </div>
                    <div className='input_wrap'>
                        <label htmlFor="link_url">Paste Link Here:</label>
                        <input type="text" className="input_field paste_url" />
                    </div>
                    <div class="drop-zone">
                        <div class="drop-zone__prompt">
                            <img src={FilePlus} alt="" /> <br />

                            <div className='drag-details'>

                                <span> Drag and Drop files here </span>
                                <span> file format: mp3/mp4/pdf</span>
                                <button className='file-btn'>browse</button>
                                <h4>maximum size: <p>10mb</p> </h4>

                            </div>
                        </div>
                        <input type="file" name="myFile" class="drop-zone__input"
                            hidden />
                    </div>
     


                </form>
                <div className='checkout-process-btn'>
                        <button
                            onClick={() => setIsProceed(true)}
                        >proceed to summary</button>
                    </div>
                {isProceed && <div className=" mobile-ad-wrapper">
                    <h3>campaign summary</h3>
                    <div className="mobile-ad-card">
                        <img src={BeatFm} alt="" />
                        <div>
                            <h4>Beat FM</h4>
                            <span>NGN 235,200.00</span>
                        </div>
                    </div>
                    <div className="mobile-ad-card">
                        <img src={ClassicFm} alt="" />
                        <div>
                            <h4>Beat FM</h4>
                            <span>NGN 235,200.00</span>
                        </div>
                    </div>
                    <div className='mobile-ad-card'>
                        <img src={BeatFm} alt="" />
                        <div>
                            <h4>Beat FM</h4>
                            <span>NGN 235,200.00</span>
                        </div>
                    </div>
                    <div className='ad-card-footer' >
                        <div className='ad-footer-info'>
                            <h2>subtotal</h2>
                            <span>NGN 1,492,898.00</span>
                        </div>
                        <div className='ad-footer-info'>
                            <h2 >vat 7.5%</h2>
                            <span>NGN 1,492,898.00</span>
                        </div>
                        <div className='ad-footer-info'>
                            <h2>special adviser discount</h2>
                            <span>NGN 1,492,898.00</span>
                        </div>
                        <div className='ad-footer-info'>
                            <h2>grand total</h2>
                            <span>NGN 1,492,898.00</span>
                        </div>
                        <div className='ad-footer-info ad-action-wrapper'>
                            <h2>cancel</h2>
                            <button>continue</button>
                        </div>
                    </div>
                </div>}
                <div className="ad-wrapper">
                    <h3>campaign summary</h3>
                    <div className="ad-card">
                        <img src={BeatFm} alt="" />
                        <div>
                            <h4>Beat FM</h4>
                            <span>NGN 235,200.00</span>
                        </div>
                    </div>
                    <div className="ad-card">
                        <img src={ClassicFm} alt="" />
                        <div>
                            <h4>Beat FM</h4>
                            <span>NGN 235,200.00</span>
                        </div>
                    </div>
                    <div className='ad-card'>
                        <img src={BeatFm} alt="" />
                        <div>
                            <h4>Beat FM</h4>
                            <span>NGN 235,200.00</span>
                        </div>
                    </div>
                    <div className='ad-card-footer' >
                        <div className='ad-footer-info'>
                            <h2>subtotal</h2>
                            <span>NGN 1,492,898.00</span>
                        </div>
                        <div className='ad-footer-info'>
                            <h2 >vat 7.5%</h2>
                            <span>NGN 1,492,898.00</span>
                        </div>
                        <div className='ad-footer-info'>
                            <h2>special adviser discount</h2>
                            <span>NGN 1,492,898.00</span>
                        </div>
                        <div className='ad-footer-info'>
                            <h2>grand total</h2>
                            <span>NGN 1,492,898.00</span>
                        </div>
                        <div className='ad-footer-info ad-action-wrapper'>
                            <h2>cancel</h2>
                            <button>continue</button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
      </main>
      <footer>
        <MainFooterSect />
      </footer>
    </div>
  );
};

export default PageThree;
