import React from 'react'
import "./style.css"

const Slot = () => {
    return (
        <div className='slot-modal-wrapper'>
            <div className='slot-container'>
                <div className='cancel-icon-wrapper'>
                <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1558_2136)">
<path d="M14.25 4.25L4.75 12.75" stroke="#0E0E2C" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.75 4.25L14.25 12.75" stroke="#0E0E2C" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1558_2136">
<rect width="19" height="17" fill="white"/>
</clipPath>
</defs>
</svg>

                </div>
                <h4>Input Slot Numbers for 19 June, 2022</h4>
                <div className='slot-time-wrapper'>
                    <div>
                        <button>12:00am -12:59am</button>
                        <input type='text' placeholder='Enter Slot no' />
                    </div>
                    <div>
                        <button>12:00am -12:59am</button>
                        <input type='text' placeholder='Enter Slot no' />
                    </div>
                    <div>
                        <button>12:00am -12:59am</button>
                        <input type='text' placeholder='Enter Slot no' />
                    </div>
                    <div>
                        <button>12:00am -12:59am</button>
                        <input type='text' placeholder='Enter Slot no' />
                    </div>
                    <div>
                        <button>12:00am -12:59am</button>
                        <input type='text' placeholder='Enter Slot no' />
                    </div>
                    <div>
                        <button>12:00am -12:59am</button>
                        <input type='text' placeholder='Enter Slot no' />
                    </div>
                    <div>
                        <button>12:00am -12:59am</button>
                        <input type='text' placeholder='Enter Slot no' />
                    </div>
                    <div>
                        <button>12:00am -12:59am</button>
                        <input type='text' placeholder='Enter Slot no' />
                    </div>
                    <div>
                        <button>12:00am -12:59am</button>
                        <input type='text' placeholder='Enter Slot no' />
                    </div>
                    <div>
                        <button>12:00am -12:59am</button>
                        <input type='text' placeholder='Enter Slot no' />
                    </div>
                    <div>
                        <button>12:00am -12:59am</button>
                        <input type='text' placeholder='Enter Slot no' />
                    </div>
                    <div>
                        <button>12:00am -12:59am</button>
                        <input type='text' placeholder='Enter Slot no' />
                    </div>
                    <div>
                        <button>12:00am -12:59am</button>
                        <input type='text' placeholder='Enter Slot no' />
                    </div>
                    <div>
                        <button>12:00am -12:59am</button>
                        <input type='text' placeholder='Enter Slot no' />
                    </div>
                </div>
                <div className='agreement-wrapper'>
                    <input type='checkbox' />
                    <span>The spots and slot numbers should be duplicated across all dates</span>
                </div>
                <div className='agreement-btn'>
                    <button >Save and Continue</button>

                </div>
            </div>
        </div>
    )
}

export default Slot