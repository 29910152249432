import React from "react";
import "../css/sect-quart.css";
import Reviewer1 from "../assets/images/reviews/reviewer1.png";
import Reviewer2 from "../assets/images/reviews/reviewer2.png";
import Reviewer3 from "../assets/images/reviews/reviewer3.png";
import { ReactComponent as Star } from "../assets/icons/star.svg";

const SectQuart = ()=> {
    return(
        <section id="section-quart">
            <div>
                <div id="reviewer-details">
                    <div>
                        <img src={Reviewer1} alt="" />
                        <div>
                            <h3>Shade Benson</h3>
                            <h4>Chief Marketer, CocaCola</h4>
                        </div>
                    </div>
                    <div>
                        <img src={Reviewer2} alt="" />
                        <div>
                            <h3>Chidima Udoeke</h3>
                            <h4>CEO, CommunicationsArc</h4>
                        </div>
                    </div>
                    <div>
                        <img src={Reviewer3} alt="" />
                        <div>
                            <h3>George Mills</h3>
                            <h4>Ads Specialist, IBM</h4>
                        </div>
                    </div>
                </div>
                <div id="reviewer-review">
                    <h2>It was a great experience</h2>
                    <div>
                        <Star className="sq-star-rate" />
                        <Star className="sq-star-rate" />
                        <Star className="sq-star-rate" />
                        <Star className="sq-star-rate" />
                        <Star className="sq-star-rate" />
                    </div>
                    <blockquote>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
                        nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in <br />
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia 
                        deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, 
                        consectetur adipiscing elit, sed do eiusmod tempor 
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                    </blockquote>
                </div>
            </div>
        </section>
    )
}

export default SectQuart;