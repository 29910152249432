import React, { useRef, useState, useEffect } from "react";
import "./css/station.css";

const Station = ({
  id,
  img,
  title,
  currency,
  price,
  discount,
  clearSelect,
  select,
  deSelect,
}) => {
  const [selectStat, setSelectStat] = useState(false);
  const checkbox = useRef();

  useEffect(() => {
    if (!clearSelect) {
      setSelectStat(clearSelect);
    }
  }, [clearSelect]);
  return (
    <div className="station-box">
      <div className="toggle-select">
        <button
          onClick={() => {
            const data = {
              id: id,
              name: title,
              price: price - (price * discount) / 100,
            };
            if (selectStat === false) {
              select(data);
              setSelectStat(true);
            } else {
              deSelect(data);
              setSelectStat(false);
            }
          }}
        >
          <svg
            className={selectStat ? "stat-selected" : null}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            {/*! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
            <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
          </svg>
        </button>
        <input type="checkbox" ref={checkbox} />
      </div>
      <div className="station-img">
        <img src={img} alt="" />
      </div>
      <div className="txt-cnt">
        <h3 className="station-title">{title}</h3>
        <h4>
          Starting From{" "}
          <span className="station-prev-price">
            {currency}
            {price.toFixed(2).toLocaleString()}
          </span>{" "}
          {discount.toLocaleString() + "%"}
        </h4>
        <h3 className="station-new-price">
          {currency}
          {(price - (price * discount) / 100).toFixed(2).toLocaleString()}
        </h3>
      </div>
    </div>
  );
};

export default Station;
