import React from 'react'

import CardImageOne from "./ResponsiveAdvertiserDashboard/assets/cardIcon1.svg"
import CardImageTwo from "./ResponsiveAdvertiserDashboard/assets/cardIcon1.svg"
import CardImageThree from "./ResponsiveAdvertiserDashboard/assets/cardIcon1.svg"
import "./ResponsiveAdvertiserDashboard/AdvertiserPages/css/dashboard.css"
import VendorDashbaordLayout from './ResponsiveAdvertiserDashboard/ResponsiveAdvertiserDashboardLayout/vendorDashboard/VendorDashbaordLayout';
import { useQuery } from "@apollo/client"
import { USER_INFO } from '../components/GraphQL/Queries'


const DashboardVendor = () => {
    const {data} = useQuery(USER_INFO)
    console.log(data, 'data...')
    return (
 <VendorDashbaordLayout>
             <div className='dashboard-home-container'>
                <div className='dashboard-card-container'>
                    <div className='card-content-wrapper'>
                        <img src={CardImageOne}
                        alt='campaign-icon'
                         />
                        <div>
                            <span>22</span>
                            <span>Active Campaigns</span>
                        </div>
                    </div>
                    <div className='card-content-wrapper'>
                        <img src={CardImageTwo}
                        alt='total-campaign-icon'
                        />
                        <div>
                            <span>135</span>
                            <span>Total Campaigns</span>
                        </div>
                    </div>
                    <div className='card-content-wrapper'>
                        <img src={CardImageThree} 
                        alt='pending-campaign-icon'
                        />
                        <div>
                            <span>09</span>
                            <span>Pending Campaigns</span>
                        </div>
                    </div>
                </div>
                <div
                    className='dashboard-table-container'>
                        <h3>Most Recent Campaigns</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>campaign ID</th>
                                <th>campaign details</th>
                                <th>campaign status</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            </VendorDashbaordLayout>
    )
}

export default DashboardVendor;