import React from 'react'
import AdvertDashboardLayout from '../../ResponsiveAdvertiserDashboardLayout/AdvertDashboardLayout'
import FilterLayout from './filter-layout'
import "../css/station-dashboard.css"

const StationDashboard = () => {
    const data = [
        {
            invoiceNo: "#467666",
            campaignName: "Awoof Data",
            companyName: "mtn",
            industry: "Entertainment",
            date: "mar 20th 2022",
            radioStation: "beat 99 fm",


        },
        {
            invoiceNo: "#467666",
            campaignName: "Awoof Data",
            companyName: "mtn",
            industry: "Entertainment",
            date: "mar 20th 2022",
            radioStation: "beat 99 fm",


        },
        {
            invoiceNo: "#467666",
            campaignName: "Awoof Data",
            companyName: "mtn",
            industry: "Entertainment",
            date: "mar 20th 2022",
            radioStation: "beat 99 fm",


        },
        {
            invoiceNo: "#467666",
            campaignName: "Awoof Data",
            companyName: "mtn",
            industry: "Entertainment",
            date: "mar 20th 2022",
            radioStation: "beat 99 fm",


        },
        {
            invoiceNo: "#467666",
            campaignName: "Awoof Data",
            companyName: "mtn",
            industry: "Entertainment",
            date: "mar 20th 2022",
            radioStation: "beat 99 fm",


        },
        {
            invoiceNo: "#467666",
            campaignName: "Awoof Data",
            companyName: "mtn",
            industry: "Entertainment",
            date: "mar 20th 2022",
            radioStation: "beat 99 fm",


        },
    ]
  return (
    <AdvertDashboardLayout 
    header="Agnes Hassan">
        <div className='station-container'>
<FilterLayout />
        </div>
        <div className='station-table-container'>
        <table>
            <thead>
                <tr>
                <th>
                <input name="select_all" value="1" type="checkbox" />
                </th>
                <th>Invoice No.</th>
                <th>Campaign Name</th>
                <th>Company Name</th>
                <th>Industry</th>
                <th>Date</th>
                <th>Radio Station</th>
                </tr>
                
            </thead>
            <tbody>
                {data.map((tableinfo) => {
                    return <tr>
                        <td>
                <input name="select_all" value="1" type="checkbox" />

                        </td>
                        <td>{tableinfo.invoiceNo}</td>
                        <td>{tableinfo.campaignName}</td>
                        <td>{tableinfo.companyName}</td>
                        <td>{tableinfo.industry}</td>
                        <td>{tableinfo.date}</td>
                        <td>{tableinfo.radioStation}</td>
                    </tr>
                })}
                </tbody>
        </table>
        </div>
    </AdvertDashboardLayout>
    )
}

export default StationDashboard