import React, { useState } from 'react'
import AdvertDashboardLayout from '../../ResponsiveAdvertiserDashboardLayout/AdvertDashboardLayout'
import OrderIcon from "../../assets/order.svg"
import StatusIcon  from "../../assets/status.svg"
import StartDateIcon from "../../assets/calendar.svg"
import EndDateIcon from "../../assets/endDateCalendar.svg"
import AdvertTypeIcon from "../../assets/advertType.svg"
import DurationIcon from "../../assets/time.svg"
import SpotIcon from "../../assets/spot.svg"
import LanguageIcon from "../../assets/language.svg"
import DaysIcon from "../../assets/duration.svg"
import DollarIcon from "../../assets/currency.svg"
import CardIcon from "../../assets/card.svg"
import "../css/station-booking.css"

const StationBooking = () => {
    const radioButton = ["beat fm", "classic fm", "naija fm"]
    const [activeButton, setActiveButton] = useState(0)
  return (
    <AdvertDashboardLayout>
         <div className='advertiser-booking-container'>
                <div>
                    <h4>#467666</h4>
                    <div className='booking-document-wrapper'>
                        <label>Campaign Document</label>
                        <select>
                            <option>Freshyo Jingle</option>
                            <option>Freshyo Jingle</option>
                        </select>
                    </div>
                </div>
                <div className='station-btn'>
                    {radioButton.map((btn, index) => {
                        return <button
                        onClick={()=> setActiveButton(index)
                          
                        }
                        className={index === activeButton ? "active": ""}
                        >{btn}</button>
                    })}
                </div>
                <div className='station-info-wrapper'>
                    <div className='station-header-info'>
                        <div>
                            <img src={OrderIcon} alt='order_icon'/>
                            <span>Order ID: 467666</span>
                        </div>
                        <div>
                            <img src={StatusIcon} alt='status_icon'/>
                            <span>Status: Approved</span>
                        </div>
                        <div>
                            <img src={StartDateIcon} alt='start-date_icon'/>
                            <span>Start Date: Mar 28th 2022</span>
                        </div>
                        <div>
                            <img src={EndDateIcon} alt='end-date_icon'/>
                            <span>End Date: Jun 25th 2022</span>
                        </div>
                    </div>
                    <div className='station-booking-cards-container'>
                        <div className='station-card'>
                            <img src={AdvertTypeIcon} alt='advert-type_icon'/>
                            <span>
                            Adert Type Jingle

                            </span>
                        </div>
                        <div className='station-card'>
                            <img src={DurationIcon} alt='duration_icon'/>
                            <span>
                            Duration: 2 weeks

                            </span>
                        </div>
                        <div className='station-card'>
                            <img src={SpotIcon} alt='spot_icon'/>
                            <span>
                            Number of Spot: 4

                            </span>
                        </div>
             
                        <div className='station-card'>
                            <img src={LanguageIcon} alt='language_icon'/>
                            <span>
                            Language: english

                            </span>
                        </div>
                        <div className='station-card'>
                            <img src={DaysIcon} alt='days_icon'/>
                            <span>
                            Number of Days: 28

                            </span>
                        </div>
                     
                        <div className='station-card'>
                            <img src={DollarIcon} alt='currency_icon'/>
                            <span>
                            Total amount: #176,000

                            </span>
                        </div>
                        <div className='station-card'>
                            <img src={CardIcon} alt='card_icon'/>
                            <span>
                            Payment Method: Paystack

                            </span>
                        </div>
                    </div>
                </div>
            </div>
    </AdvertDashboardLayout>
  )
}

export default StationBooking