import React from 'react';
import '../css/sect-trois.css';
import SectTroisImg from "../assets/images/startacampaign.png";

const SectTrois = ({ sectTroisClass, mainHeading, subHeading, para, buttonTxt, a, b })=> {
    return(
        <section id="section-trois" className={sectTroisClass}>
            <div>
                <img id="call-to-action-lg" src={SectTroisImg} alt="" />
                <article>
                    <h2>{mainHeading}</h2>
                    <img id="call-to-action-mb" src={SectTroisImg} alt="" />
                    <h3>{subHeading}</h3>
                    <p>{para}</p>
                    <button>{buttonTxt}</button>
                    <div className='other'>
                        <button>{a}</button>
                        <button>{b}</button>

                    </div>

                </article>
            </div>
            
        </section>
    )
}

export default SectTrois;