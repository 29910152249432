import React, { useState } from 'react'
import {Link} from "react-router-dom"
import { USER_INFO } from '../../../components/GraphQL/Queries'
import Logo from '../../../components/Logo'
import Hamburger from "../assets/hamburger.svg"
import Notification from "../assets/notification.svg"
import UserIcon from "../assets/user.svg"
import PersonIcon from "../assets/person.svg"

import DashboardIcon from "../assets/dashboard.svg"
import CampaignIcon from "../assets/campaign.svg"
import DraftIcon from "../assets/draft.svg"
import WalletIcon from "../assets/wallet.svg"
import BalanceIcon from "../assets/balance.svg"
import AnalyticIcon from "../assets/analytic.svg"
import { useQuery } from '@apollo/client';
import "./style.css"

const AdvertDashboardLayout = ({ header, children }) => {
    const menuData = [
        {
            img: DashboardIcon,
            text: "Advertiser Dashboard",
            link: "/dashboard"
        },
        {
            img: CampaignIcon,
            text: "Campaigns",
            link: "/dashboard/station"
        },
        {
            img: DraftIcon,
            text: "Drafts",
            link: "/dashboard/draft"
        },
        {
            img: WalletIcon,
            text: "Wallet",
            link: "/dashboard/wallet"
        },
        {
            img: AnalyticIcon,
            text: "Analytics",
            link: ""
        },
        {
            img: PersonIcon,
            text: "Profile",
            link: "/dashboard/profile"
        }

    ]
    const [isOpen, setIsOpen] = useState(false);
    // const [fullname, setFullName] = useState("")
    const { data } = useQuery(USER_INFO)
    // useEffect(() => {
    //     setFullName
    //     (data.auth.me.fullname)
    // }, [fullname])

    

    console.log(data?.auth.me.fullname)

    return (
        <>
        <div className="advert-dashboard-layout">
            <nav>
                <div className='advert-dashboard-nav-container'>
                    <div className='nav-wrapper'>
                        <div>
                            <Logo
                                textColor="#FFFFFE"
                                favColor="#FC9732"
                            />

                        </div>
                        <div onClick={() => setIsOpen(!isOpen)}>
                            <img src={Hamburger} alt="hamburger_icon" />
                        </div>
                    </div>


                    <div className='advertiser-name'>{data?.auth.me.fullname}</div>
                </div>
                {isOpen && <div className='mobile-show-nav-menu-container'>
                    <div className='mobile-sidebar-wrapper'>
                        <div>
                            <Logo
                                textColor="#FFFFFE"
                                favColor="#FC9732"
                            />

                        </div>
                        <div className='mobile-icon-wrapper'>
                       
                            <img src={Notification} alt="notification_icon" />
                            <img src={UserIcon} alt="user_icon" />
                        </div>
                    </div>
                    <div className='mobile-sidebar-menu'>
                        {menuData.map((data) => {
                            return     <Link to={data.link}>

                            <div className='mobile-sidebat-item'>
                                <img src={data.img} alt={data.text}/>
                                <span>{data.text}</span>
                            </div>
                            </Link>

                        })}
                    </div>
                </div>}
            </nav>

            {children}
        </div>

            <div className='desktop-dashboard-layout'>
                <div className='dashboard-sidebar-container'>
                    <div>
                    <Logo
                        textColor="#FFFFFE" favColor="#FC9732"
                    />
                    </div>
                
                    <div className='dashboard-sidebar-menu-items'>
                        {menuData.map((item) => {
                            return  <Link to={item.link}>
                            
                            <div className="sidebar-menu-item">
                                <img src={item.img} alt={item.text}/>
                                <span>{item.text}</span>
                            </div>
                            </Link>

                        })}
                    </div>
                </div>
                <div className='dashboard-nav-body-container'>
                    <nav>
                        <div>{data?.auth.me.fullname}</div>
                        <div className='nav-icon-wrapper'>
                        <img src={BalanceIcon} alt='balance_icon' />

                            <img src={Notification} alt='notification' />
                            <img src={UserIcon} alt='user-icon'/>
                        </div>
                    </nav>
                    {children}
                </div>
            </div>
            </>
    )
}

export default AdvertDashboardLayout;