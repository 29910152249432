import React from 'react'
import AdvertDashboardLayout from '../../ResponsiveAdvertiserDashboardLayout/AdvertDashboardLayout'
import CardImageOne from "../../assets/cardIcon1.svg"
import CardImageTwo from "../../assets/cardIcon2.svg"
import CardImageThree from "../../assets/cardIcon3.svg"
import "../css/dashboard.css"



const Dashboard = () => {
    return (
        <AdvertDashboardLayout>
            <div className='dashboard-home-container'>
                <div className='dashboard-card-container'>
                    <div className='card-content-wrapper'>
                        <img src={CardImageOne}
                            alt='campaign-icon'
                        />
                        <div>
                            <span>22</span>
                            <span>Active Campaigns</span>
                        </div>
                    </div>
                    <div className='card-content-wrapper'>
                        <img src={CardImageTwo}
                            alt='total-campaign-icon'
                        />
                        <div>
                            <span>135</span>
                            <span>Total Campaigns</span>
                        </div>
                    </div>
                    <div className='card-content-wrapper'>
                        <img src={CardImageThree}
                            alt='pending-campaign-icon'
                        />
                        <div>
                            <span>09</span>
                            <span>Pending Campaigns</span>
                        </div>
                    </div>
                </div>
                <div
                    className='dashboard-table-container'>
                    <h3>Most Recent Campaigns</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>campaign ID</th>
                                <th>campaign details</th>
                                <th>campaign status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </AdvertDashboardLayout>
    )
}

export default Dashboard;