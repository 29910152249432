import React, { useState } from "react";
import { Link } from "react-router-dom"
import "./css/recommended-station.css";
import Station from "../components/Station.js";

import LagTalkImg from "../../assets/images/stations/lagos-talks.png";
import NaijaFMImg from "../../assets/images/stations/naijafm.png";
import ClassicFMImg from "../../assets/images/stations/classicfm.png";

const RecommendedStation = () => {
  const [selectedStation, setSelectedStation] = useState([]);
  const [clearSelect, setClearSelect] = useState(false);

  let RecommendedStationData = [
    {
      stationImg: LagTalkImg,
      stationTitle: "Lagos Talks  91.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: NaijaFMImg,
      stationTitle: "Naija  102.7FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: ClassicFMImg,
      stationTitle: "Classic  97.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: LagTalkImg,
      stationTitle: "Lagos Talks  91.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: NaijaFMImg,
      stationTitle: "Naija  102.7FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: ClassicFMImg,
      stationTitle: "Classic  97.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: LagTalkImg,
      stationTitle: "Lagos Talks  91.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: NaijaFMImg,
      stationTitle: "Naija  102.7FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: ClassicFMImg,
      stationTitle: "Classic  97.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: LagTalkImg,
      stationTitle: "Lagos Talks  91.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: NaijaFMImg,
      stationTitle: "Naija  102.7FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: ClassicFMImg,
      stationTitle: "Classic  97.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: LagTalkImg,
      stationTitle: "Lagos Talks  91.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: NaijaFMImg,
      stationTitle: "Naija  102.7FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: ClassicFMImg,
      stationTitle: "Classic  97.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: LagTalkImg,
      stationTitle: "Lagos Talks  91.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: NaijaFMImg,
      stationTitle: "Naija  102.7FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: ClassicFMImg,
      stationTitle: "Classic  97.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: LagTalkImg,
      stationTitle: "Lagos Talks  91.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: NaijaFMImg,
      stationTitle: "Naija  102.7FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: ClassicFMImg,
      stationTitle: "Classic  97.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: LagTalkImg,
      stationTitle: "Lagos Talks  91.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: NaijaFMImg,
      stationTitle: "Naija  102.7FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
    {
      stationImg: ClassicFMImg,
      stationTitle: "Classic  97.3FM",
      stationCurrency: "NGN",
      stationPrice: 4200,
      discount: 10,
    },
  ];

  let selectStation = (data) => {
    setSelectedStation([...selectedStation, data]);
    setClearSelect(true);
  };
  let deSelectStation = (data) => {
    setSelectedStation(selectedStation.filter((a) => a.id !== data.id));
  };

  return (
    <div id="recommended-station">
      <div>
        <h2>Recommended For You</h2>
        <h3>Select one or more Radio Stations</h3>
      </div>
      <div>
        <div id="filter-station-ctrl">
          <button>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.3335 12.2503V8.16699"
                stroke="#F99B28"
                strokeWidth="1.16667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.3335 5.83333V1.75"
                stroke="#F99B28"
                strokeWidth="1.16667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 12.25V7"
                stroke="#F99B28"
                strokeWidth="1.16667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 4.66667V1.75"
                stroke="#F99B28"
                strokeWidth="1.16667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.6665 12.2497V9.33301"
                stroke="#F99B28"
                strokeWidth="1.16667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.6665 7V1.75"
                stroke="#F99B28"
                strokeWidth="1.16667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.583496 8.16699H4.0835"
                stroke="#F99B28"
                strokeWidth="1.16667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.25 4.66699H8.75"
                stroke="#F99B28"
                strokeWidth="1.16667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.9165 9.33301H13.4165"
                stroke="#F99B28"
                strokeWidth="1.16667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Filter</span>
          </button>
          <button
            onClick={() => {
              setSelectedStation([]);
              setClearSelect(false);
            }}
          >
            Clear all
          </button>
        </div>
        <div id="recommended-station-list">
          <div>
            {RecommendedStationData.map((station, index) => {
              return (
                <Station
                  key={index}
                  id={index}
                  img={station.stationImg}
                  title={station.stationTitle}
                  currency={station.stationCurrency}
                  price={station.stationPrice}
                  discount={station.discount}
                  clearSelect={clearSelect}
                  select={selectStation}
                  deSelect={deSelectStation}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div>
        <Link to="/book-campaign/campaign-details">
        <button>Book Now</button>
        </Link>
      </div>
    </div>
  );
};

export default RecommendedStation;
