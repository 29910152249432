import React, { useState } from 'react'
import AdvertDashboardLayout from '../../ResponsiveAdvertiserDashboardLayout/AdvertDashboardLayout'
import CardOne from "../../assets/paymentCard.svg"
import CardTwo from "../../assets/paymentCardTwo.svg"
import CardThree from "../../assets/paymentCardThree.svg"
import FilePlus from "../../assets/file-plus.svg"
import BeatFm from "../../assets/beatfm.svg"
import ClassicFm from "../../assets/classicfm.svg"
import { useMutation } from '@apollo/client'
import { CREATE_CAMPAIGN } from '../../../../components/GraphQL/Mutation'
import "../css/checkout.css"

const Checkout = () => {

    const [createCampaign, setCreateCampaign] = useState({
        name: "",
        companyName: "",
        industryName: "",
        links: "",
        files: ""
    })
    const handleChange = (e) => {
        setCreateCampaign({ ...createCampaign, [e.target.name]: e.target.value })
    }
    const [filename, setFile] = useState('')
    const saveFile = ({
        target: {
            validity,
            files: [files]
        }
    }) => {
        if (validity.valid) {
            setCreateCampaign({...createCampaign, files:files})
        }
        console.log(createCampaign)
    }

    //         setCreateCampaign((prev)=>{
    // const newObj = {...prev, files: e.target.files[0]}
    // console.log(newObj, 'new-obj')
    // return newObj
    //         })

    //         setFile(e.target.files[0]);
    //         console.log(createCampaign)
    // }
    const [isProceed, setIsProceed] = useState(false)
    const [campaign, { data }] = useMutation(CREATE_CAMPAIGN, {
        context: {
          headers: {
            'apollo-require-preflight': true,
          },
        }})
    console.log(data)
    const submitCampaign = (
    //     {
    //     target: {
    //         validity,
    //         files: [files]
    //     }
    // }
    ) => {


        console.log(createCampaign, 'data...')
        //     const formData = new FormData();

        //     formData.append("file", filename);
        //     formData.append("campaignInfo", createCampaign);
        // console.log(formData, 'llll')
        // if (validity.valid) {
            campaign({
                
                variables: {
                    name: createCampaign.name,
                    companyName: createCampaign.companyName,
                    industryName: createCampaign.industryName,
                    links: createCampaign.links,
                    files: createCampaign.files
                    

                }
            })
        // }
        // if (validity.valid) {
        //     campaign({

        //         variables: {

        //             name: createCampaign.name,
        //             companyName: createCampaign.companyName,
        //             industryName: createCampaign.industryName,
        //             links: createCampaign.links,
        //             file

        //         }
        //     })
        //     }

    }



    // const newCampaign = (e) => {
    //     e.preventDefault()
    //     campaign({
    //         variables: {
    //             input: {
    //                 name: createCampaign.name,
    //                 companyName: createCampaign.companyName,
    //                 industryName: createCampaign.industryName,
    //                 filename: createCampaign.file,
    //                 links: createCampaign.link
    //             }
    //         }
    //     })
    // }


    return (
        <AdvertDashboardLayout>
            <div className='checkout-container'>
                <div className='checkout-header'>
                    <h4>campaign details</h4>
                    <div>
                        <span>wallet balance: </span>
                        <span>NGN 1,000</span>
                    </div>
                </div>
                <div className='checkout-wrapper'>
                    <form>
                        <h4 className='card-option-wrapper'>Select your preffered payment method</h4>
                        <div className='payment-card-wrapper'>
                            <div>
                                <div>
                                    <img src={CardOne}
                                        alt='wallet-icon'
                                    />

                                </div>
                                <span className='card-type-wrapper'>
                                    PAY FROM WALLET
                                </span>
                            </div>
                            <div>
                                <div>
                                    <img src={CardTwo}
                                        alt='paystack-icon'
                                    />

                                </div>
                                <span
                                    className='card-type-wrapper'
                                >
                                    PAY WITH PAYSTACK

                                </span>
                            </div>
                            <div>
                                <div>
                                    <img src={CardThree}
                                        alt='flutter-icon'
                                    />

                                </div>
                                <span
                                    className='card-type-wrapper'
                                >
                                    PAY WITH FLUTTERWAVE
                                </span>

                            </div>
                        </div>
                        <div className='input_wrap'>
                            <label htmlFor="campaign_name">Campaign Name:</label>
                            <input type="text" placeholder="Enter campaign name" className="input_field"
                                name='name'
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input_wrap'>
                            <label htmlFor="company_name">Name of Company :</label>
                            <input type="text" placeholder="Enter your ompany name" className="input_field"
                                name='companyName'
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input_wrap'>
                            <label htmlFor="industry_name">Name of Industry:</label>
                            <input type="text" placeholder="Enter industry name" className="input_field"
                                name='industryName'
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input_wrap'>
                            <label htmlFor="link_url">Paste Link Here:</label>
                            <input type="text" placeholder="Enter campaign name" className="input_field paste_url"
                                name='links'
                                onChange={handleChange}
                            />
                        </div>
                        <div class="drop-zone">
                            <div class="drop-zone__prompt">
                                <img src={FilePlus} alt="" /> <br />

                                <div className='drag-details'>

                                    <span> Drag and Drop files here </span>
                                    <span> file format: mp3/mp4/pdf</span>
                                    <button className='file-btn'>browse</button>
                                    <span>{filename.name ? filename.name : ''}</span>
                                    <h4>maximum size: <p>10mb</p> </h4>

                                </div>
                            </div>
                            <input type="file" name="files" class="drop-zone__input"
                                hidden onChange={saveFile} />
                        </div>



                    </form>
                    <div className='checkout-process-btn'>
                        <button
                            onClick={() => {
                                // setIsProceed(true)
                                submitCampaign()
                            }}
                        >proceed to summary</button>
                    </div>
                    {isProceed && <div className=" mobile-ad-wrapper">
                        <h3>campaign summary</h3>
                        <div className="mobile-ad-card">
                            <img src={BeatFm} alt="" />
                            <div>
                                <h4>Beat FM</h4>
                                <span>NGN 235,200.00</span>
                            </div>
                        </div>
                        <div className="mobile-ad-card">
                            <img src={ClassicFm} alt="" />
                            <div>
                                <h4>Beat FM</h4>
                                <span>NGN 235,200.00</span>
                            </div>
                        </div>
                        <div className='mobile-ad-card'>
                            <img src={BeatFm} alt="" />
                            <div>
                                <h4>Beat FM</h4>
                                <span>NGN 235,200.00</span>
                            </div>
                        </div>
                        <div className='ad-card-footer' >
                            <div className='ad-footer-info'>
                                <h2>subtotal</h2>
                                <span>NGN 1,492,898.00</span>
                            </div>
                            <div className='ad-footer-info'>
                                <h2 >vat 7.5%</h2>
                                <span>NGN 1,492,898.00</span>
                            </div>
                            <div className='ad-footer-info'>
                                <h2>special adviser discount</h2>
                                <span>NGN 1,492,898.00</span>
                            </div>
                            <div className='ad-footer-info'>
                                <h2>grand total</h2>
                                <span>NGN 1,492,898.00</span>
                            </div>
                            <div className='ad-footer-info ad-action-wrapper'>
                                <h2>cancel</h2>
                                <button>continue</button>
                            </div>
                        </div>
                    </div>}
                    <div className="ad-wrapper">
                        <h3>campaign summary</h3>
                        <div className="ad-card">
                            <img src={BeatFm} alt="" />
                            <div>
                                <h4>Beat FM</h4>
                                <span>NGN 235,200.00</span>
                            </div>
                        </div>
                        <div className="ad-card">
                            <img src={ClassicFm} alt="" />
                            <div>
                                <h4>Beat FM</h4>
                                <span>NGN 235,200.00</span>
                            </div>
                        </div>
                        <div className='ad-card'>
                            <img src={BeatFm} alt="" />
                            <div>
                                <h4>Beat FM</h4>
                                <span>NGN 235,200.00</span>
                            </div>
                        </div>
                        <div className='ad-card-footer' >
                            <div className='ad-footer-info'>
                                <h2>subtotal</h2>
                                <span>NGN 1,492,898.00</span>
                            </div>
                            <div className='ad-footer-info'>
                                <h2 >vat 7.5%</h2>
                                <span>NGN 1,492,898.00</span>
                            </div>
                            <div className='ad-footer-info'>
                                <h2>special adviser discount</h2>
                                <span>NGN 1,492,898.00</span>
                            </div>
                            <div className='ad-footer-info'>
                                <h2>grand total</h2>
                                <span>NGN 1,492,898.00</span>
                            </div>
                            <div className='ad-footer-info ad-action-wrapper'>
                                <h2>cancel</h2>
                                <button>continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </AdvertDashboardLayout>
    )
}

export default Checkout