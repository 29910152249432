import React from "react"
// import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import {
  ApolloClient, 
  InMemoryCache, 
  ApolloProvider,
  HttpLink,
  from,
  ApolloLink
} from "@apollo/client"
import { createUploadLink } from "apollo-upload-client"
import { onError } from "@apollo/client/link/error"
import { setContext } from '@apollo/client/link/context'
import './App.css';
import { Outlet } from "react-router-dom";
import { AUTH_TOKEN } from "./constant";


function App() {
  // const client = new ApolloClient({
  //   uri: 'https://http://localhost:3000/api/v3',
  //   cache: new InMemoryCache(),
  // });
  const errorLink = onError(({graphqlErrors, networkError}) => {
    if(graphqlErrors) {
      graphqlErrors.map(({message}) => {
        alert(`Graphql error ${message}`)
      })
    }

  })
  const httpLink = createUploadLink({
    uri: 'https://radadspd-server.azurewebsites.net/api/v3'
    //uri: 'http://localhost:5000/api/v3'
  })
//   const httpLink = from([
//     errorLink,
//     new HttpLink({uri: 'http://localhost:5000/api/v3'})
//   ]
// )
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  console.log(token)
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : ''
    }
  };
});
  const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache(),
  });
  return (
    <ApolloProvider client={client}>
    <div className="App">
      <Outlet />
    </div>
    </ApolloProvider>

  );
}

export default App;
