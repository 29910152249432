import React, { useState, useRef, useEffect } from "react";
import "./css/page-two.css";

import SearchRadioStation from "../components/SearchRadioStation.js";
import StationDetails from "../components/StationDetails.js";
import MainFooterSect from "../../components/MainFooterSect.js";
import TimeSchedule from "../components/TimeSchedule";

const PageTwo = () => {
  const data = [
    {
      alias: "Beat FM",
      name: "Beat 99.9FM",
      tagline: "The Heartbeat of Lagos",
      currency: "NGN",
      price: 4200,
      discount: 10,
    },
    {
      alias: "Classic FM",
      name: "Classic  97.3FM",
      tagline: "The Heartbeat of Lagos",
      currency: "NGN",
      price: 4200,
      discount: 10,
    },
    {
      alias: "Naija FM",
      name: "Naija  102.7FM",
      tagline: "The Heartbeat of Lagos",
      currency: "NGN",
      price: 4200,
      discount: 10,
    },
  ];
  const [allDetail, setAllDetail] = useState([]);
  const [currentDetail, setCurrentDetail] = useState(data[0].name);
  const [currentTagline, setCurrentTagline] = useState(data[0].tagline);
  const [prevDetail, setPrevDetail] = useState(0);
  const stationDeetsCtrl = useRef();

  useEffect(() => {
    setAllDetail(stationDeetsCtrl.current.querySelectorAll("button"));
  }, []);
  useEffect(() => {
    if (allDetail[prevDetail]) {
      allDetail[prevDetail].className = "active-station-btn";
    }
  }, [allDetail, prevDetail]);

  let showDetail = (name, tagline, event, index) => {
    setCurrentDetail(name);
    setCurrentTagline(tagline);
    allDetail[prevDetail].className = "";
    event.target.className = "active-station-btn";
    setPrevDetail(index);
  };
  const [isModal, setIsModal] = useState(false)

  const openModal = () => {
    setIsModal(true)
    console.log(isModal, 'ismodal')
  }
  
  return (
    <>
       {isModal && <TimeSchedule /> }
    
    <div>
      <main>
 

        <SearchRadioStation />
        
        <section id="station-detail">
          <div>
            <div id="station-detail-btn" ref={stationDeetsCtrl}>
              {data.map((station, index) => {
                return (
                  <button
                    key={index}
                    onClick={(e) => {
                      showDetail(station.name, station.tagline, e, index);
                    }}
                  >
                    {station.alias}
                  </button>
                );
              })}
            </div>
            <StationDetails title={currentDetail} tagline={currentTagline} isOpen={openModal}/>
         
          </div>
        </section>
      </main>
      <footer>
        <MainFooterSect />
      </footer>
    </div>
    </>
  );
};

export default PageTwo;
