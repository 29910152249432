export const AUTH_TOKEN = "token";

export const registerOption = {
name: { required: "Name is required"},
fullname: {required: "Fullname is required"},
positionHeld: {required: "specify position"},
address: {required: "Address is required"},
country: {required: "country is required"},
state: {required: "state is required"},
city: {required: "city is required"},
zipcode: {required: "specify zipcode"},
radioStationWebsite: {required: "Radio station website is required"},
radioStationCategory: {required: "Radio station category is required"},
radioStationDigitalStreaUrl: {required: "Radion state Digital Stream Url is reuired"},
phoneNumber: {
    required: "PhoneNumber is required",
    minLength: {
      value: 8,
      message: "PhoneNumber must have at least 11 digits"
    }
  },
  email: {required: "Email is required"},
  username: {required: "Username is required"},
  password: {
    required: "Password is required",
    minLength: {
      value: 8,
      message: "Password must have at least 8 characters"
    }
  },
  confirmPassword: {required: "confirm password must match password"}
}

export const loginOption = {
    email: {required: "Email is required"},
    password: {
      required: "Password is required",
      minLength: {
        value: 8,
        message: "Password must have at least 8 characters"
      }
    },
}
