import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom"
import '../css/header.css';

const Header = ({ headerBgClass, ill, title, paragraph, buttonText, agreedText, disagreedText })=> {
  const [openOption, setOpenOption] = useState(false)
    const headerTitle = useRef();
    useEffect(()=> {
        headerTitle.current.innerHTML = title;
    }, [ title ])
    
    
    return (
      <header id="main-header" className={headerBgClass}>
        <div>
          <img src={ill} alt="" />
          <article>
            <h1 ref={headerTitle}>r</h1>
            <p>{paragraph}</p>
            <button onClick={() => setOpenOption(true)}>{buttonText}</button>
            {openOption && 
                        <div>
                          
                        <button>
                        <Link to="/book-campaign/">
                          {agreedText}
                          </Link>
                          </button>
                    <button>
                    <Link to="/talk-with-an-expert">
                      {disagreedText}
                      </Link>
                      </button>
                        
                      </div>
            }
          </article>
        </div>
      </header>
    );
}

export default Header;