import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../css/navbar.css";
import Logo from "./Logo";
import { ReactComponent as Menu } from "../assets/icons/menu.svg";
import { ReactComponent as ArrowUp } from "../assets/icons/chevron-top.svg";

const Navbar = ({ bgClass, navTextColor, navFavColor, userType }) => {
  const mbMenu = useRef();
  const dropdown = useRef();

  const vendorSignup = useRef();
  const vendorLogin = useRef();
  const advertiserSignup = useRef();
  const advertiserLogin = useRef();

  useEffect(() => {
    if (userType === "vendor") {
      advertiserSignup.current.style.display = "none";
      advertiserLogin.current.style.display = "none";
    } else {
      vendorSignup.current.style.display = "none";
      vendorLogin.current.style.display = "none";
    }
  }, [userType]);

  return (
    <nav id="nav-menu" className={bgClass}>
      <div>
        <Link to="/">
          <Logo
            textColor={navTextColor}
            favColor={navFavColor} /*  , hr - background: #ABD1C61A; */
          />
        </Link>
        <hr className="header-line-one" />
        <div id="lg-nav-links">
          <ul>
            <li>
              <Link to="/">Advertisers</Link>
            </li>
            <li>
              <Link to="/broadcaster">Broadcasters</Link>
            </li>
            <li>
              <Link to="/">Knowledge Base</Link>
            </li>
            <li>
              <Link to="/">Blog</Link>
            </li>
          </ul>
          <div>
            <Link className="login" to="/login/vendor" ref={vendorLogin}>
              Log In
            </Link>
            <Link
              className="login"
              to="/login"
              ref={advertiserLogin}
            >
              Log In
            </Link>
            <Link className="signup" to="/signup/vendor" ref={vendorSignup}>
              Sign Up
            </Link>
            <Link
              className="signup"
              to="/signup"
              ref={advertiserSignup}
            >
              Sign Up
            </Link>
          </div>
        </div>
        <button
          id="menu-btn"
          ref={mbMenu}
          onClick={() => {
            dropdown.current.style.height = "238px";
          }}
        >
          <Menu />
        </button>
        <div id="mb-nav-links" ref={dropdown}>
          <ul>
            <li>
              <Link to="/">Advertisers</Link>
            </li>
            <li>
              <Link to="/broadcaster">Broadcasters</Link>
            </li>
            <li>
              <Link to="/">Knowledge Base</Link>
            </li>
            <li>
              <Link to="/">Blog</Link>
            </li>
          </ul>
          <div>
            <Link to="/login">Log In</Link>
            <Link to="/signup">Sign Up</Link>
          </div>
          <button
            className="dd-arrow-top"
            onClick={() => {
              dropdown.current.style.height = "0px";
            }}
          >
            <ArrowUp />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
